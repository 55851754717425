<div class="flex w-full flex-col items-center justify-center gap-5">
  <div class="flex w-full max-w-7xl gap-5 max-lg:flex-col">
    <div class="flex min-w-[34%] flex-col bg-lightGrayish xl:min-w-[31%]">
      <div class="flex flex-grow flex-col px-6 pt-6">
        <app-text-content-box [headline]="'Welcome back,'" [headerIcon]="'👋'"></app-text-content-box>
        <div class="flex border-b-2 border-white pb-8">
          <span class="distancePlaceholder text-lg lg:w-7 lg:text-2xl"></span>
          <span class="text-[#E6AE48] lg:text-2xl">&nbsp;{{ authService.getCurrentEmployee()?.firstname }}!</span>
        </div>
      </div>
      <div class="flex h-full flex-col">
        <app-user-overview-box
          firstText="Out"
          secondText="of Office"
          [leavesOfToday]="leavesOfToday"
          [type]="UserOberviewType.LEAVES_TODAY"
          class="my-6"
        ></app-user-overview-box>
        <app-user-overview-box
          [firstText]="'Available'"
          [secondText]="'Vacation Days'"
          [type]="UserOberviewType.VACATION"
          class="mb-6"
        ></app-user-overview-box>
        <app-user-overview-box
          [firstText]="'Current'"
          [secondText]="'Overtime'"
          [type]="UserOberviewType.OVERTIME"
          class="mb-6"
        ></app-user-overview-box>
        <app-user-overview-box
          [firstText]="'Next '"
          [secondText]="'Birthday'"
          [type]="UserOberviewType.BIRTHDAY"
          [nextBirthdayData]="employeeBirthdayData"
          class="mb-6"
        ></app-user-overview-box>
      </div>
    </div>
    <div class="flex w-full max-w-7xl flex-col gap-5 max-lg:w-full">
      <div class="h-full min-w-[67%] bg-lightGrayish">
        <app-company-goals></app-company-goals>
      </div>
      <div class="flex w-full gap-5">
        <div class="flex w-full gap-5 max-lg:flex-col max-lg:text-center">
          <app-navigation-box
            class="flex-1"
            data-cy="dash-navbox-pi"
            [icon]="'💡'"
            [firstText]="'Suggest'"
            [secondText]="'Product Idea'"
            [link]="'/product-ideas'"
          ></app-navigation-box>

          <app-navigation-box
            class="flex-1"
            data-cy="dash-navbox-fb"
            [icon]="'⚒️'"
            [firstText]="'Submit '"
            [secondText]="'Feedback'"
            [link]="'/feedback'"
          ></app-navigation-box>
        </div>
        <div class="flex w-full gap-5 max-lg:flex-col max-lg:text-center">
          <app-navigation-box
            class="flex-1"
            data-cy="dash-navbox-vac"
            [icon]="'🏖️'"
            [firstText]="'Vacation '"
            [secondText]="'Planning'"
            [link]="'/employee'"
          ></app-navigation-box>
          <app-navigation-box
            class="flex-1"
            data-cy="dash-navbox-wiki"
            [icon]="'🧠'"
            [firstText]="'Wiki'"
            (click)="navigateToUrl('https://app.clickup.com/9004057286/v/dc/8cay0p6-281')"
          ></app-navigation-box>
        </div>
      </div>
    </div>
  </div>
  <div class="w-full max-w-7xl">
    <app-revenue></app-revenue>
  </div>
  <div class="flex w-full max-w-7xl pb-5">
    <div class="flex w-full gap-5 max-lg:flex-col">
      <div class="flex w-1/2 flex-col bg-lightGrayish p-6 max-lg:w-full">
        <app-text-content-box
          [headline]="'Feedback-Cards'"
          [headerIcon]="'📓'"
          class="pb-2 lg:pb-5"
        ></app-text-content-box>
        <div class="max-h-[550px] overflow-auto pr-5">
          <app-feedbacks [isHomePage]="true"></app-feedbacks>
        </div>
      </div>
      <div class="flex w-1/2 flex-col bg-lightGrayish p-6 max-lg:w-full">
        <app-text-content-box
          [headline]="'Product Ideas'"
          [headerIcon]="'💡'"
          class="pb-2 lg:pb-5"
        ></app-text-content-box>
        <div class="max-h-[550px] overflow-auto pr-5">
          <app-product-idea-list></app-product-idea-list>
        </div>
      </div>
    </div>
  </div>
</div>
