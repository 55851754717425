<div class="flex flex-col items-center">
  <div class="mb-5 flex w-full max-w-7xl flex-col gap-4 bg-lightGrayish p-5 lg:gap-5">
    <app-text-content-box [headline]="'Part-time training'" headerIcon="🗓️" class="lg:pb-2"></app-text-content-box>
    <div class="grid gap-4 md:grid-cols-2">
      <div data-cy="training-section">
        <label class="mb-2 block text-sm font-medium">Date of training</label>
        <input
          type="text"
          mwlFlatpickr
          placeholder="Date of training"
          class="flex w-full border border-gray-300 p-2.5 px-4 py-2 placeholder-veryTransparentBlack placeholder:font-semibold"
          [altInput]="true"
          [convertModelValue]="true"
          [options]="helperService.datePickerOptions"
          [(ngModel)]="training.trainingDate"
        />
      </div>
      <form [formGroup]="timeForm">
        <label class="mb-2 block text-sm font-medium">Hours worked</label>
        <input
          formControlName="timeInput"
          (input)="helperService.onTimeInput($event, timeForm)"
          type="text"
          placeholder="HH:MM"
          formControlName="timeInput"
          data-cy="training-time"
          class="flex w-full border border-gray-300 p-2.5 px-4 py-2 placeholder-veryTransparentBlack placeholder:font-semibold"
        />
      </form>
      <div>
        <label class="mb-2 block text-sm font-medium">Description</label>

        <input
          [(ngModel)]="training.description"
          class="block h-12 w-full border border-gray-300 p-2.5 placeholder-veryTransparentBlack placeholder:font-semibold"
          data-cy="description-input"
          type="text"
          placeholder="Description"
        />
      </div>
      <div>
        <label class="mb-2 block text-sm font-medium">Training method</label>

        <app-dropdown
          #dropdownComponent
          daty-cy="ct-training-method-dropdown"
          [elements]="getTrainingTypes()"
          placeholder="Select training method"
          [selectedElement]="training.method"
          (selectElement)="onSelectTrainingType($event)"
        ></app-dropdown>
      </div>
      <div>
        <label class="mb-2 block text-sm font-medium">Reason</label>

        <input
          [(ngModel)]="training.reason"
          class="block h-12 w-full border border-gray-300 p-2.5 placeholder-veryTransparentBlack placeholder:font-semibold"
          data-cy="reason-input"
          type="text"
          placeholder="Reason for training"
        />
      </div>
    </div>

    <div class="flex w-full justify-end">
      <button
        (click)="onAddWorktime()"
        class="flex h-12 w-28 items-center justify-center border-black bg-almostBlack"
        data-cy="ct-save-button"
      >
        <img class="h-[18px] cursor-pointer" src="assets/img/icons/save.png" alt="save" />
      </button>
    </div>
  </div>

  <div
    class="mb-5 flex w-full max-w-7xl flex-col gap-4 bg-lightGrayish p-5 lg:gap-5"
    data-cy="requested-trainings-overview"
  >
    <div class="flex items-center justify-between">
      <app-text-content-box headline="Requests overview" headerIcon="🗓️" class="lg:pb-2"></app-text-content-box>
      <div class="flex gap-4">
        <label class="text-sm font-medium text-black">Past requests</label>
        <input
          type="checkbox"
          class="flex h-5 w-9 min-w-[2rem] appearance-none items-center rounded-full bg-gray-300 shadow-inner transition-all duration-300 before:ml-0.5 before:inline-block before:h-4 before:w-4 before:rounded-full before:bg-sunflowerGold checked:bg-yellow-200 checked:before:translate-x-full focus:outline-none"
          data-cy="requested-trainings-toggle"
          (change)="toggleOpenTrainings()"
        />
      </div>
    </div>
    @if (!employeeTrainings || !employeeTrainings.length) {
      <div>
        @if (!showPastRequests) {
          <span>There are currently no past requests for trainings</span>
        }
        @if (showPastRequests) {
          <span>There are currently no requests for trainings</span>
        }
      </div>
    }

    @if (employeeTrainings && employeeTrainings.length) {
      <div class="max-h-80 overflow-y-auto">
        <div class="sticky top-0 flex w-full bg-black text-white">
          <div class="min-w-[10rem] flex-1 border-r bg-black p-2">Date</div>
          <div class="min-w-[10rem] flex-1 border-r bg-black p-2">Time worked</div>
          <div class="w-[45rem] min-w-[10rem] border-r bg-black p-2">Description</div>
          <div class="min-w-[10rem] border-r bg-black p-2">Method</div>
          <div class="w-[45rem] min-w-[10rem] border-r bg-black p-2">Reason</div>
          <div class="flex min-w-[10rem] flex-1 justify-center bg-black p-2">Status</div>
        </div>
        <div>
          @for (training of employeeTrainings; track training) {
            <div class="flex w-full">
              <div class="min-w-[10rem] flex-1 border-b border-r p-2">
                {{ getTrainingDate(training) | date: 'dd.MM.yyyy' }}
              </div>
              <div class="min-w-[10rem] flex-1 border-b border-r p-2">{{ getTrainingDuration(training.duration) }}</div>
              <div
                class="w-[45rem] min-w-[10rem] overflow-hidden text-ellipsis whitespace-nowrap border-b border-r p-2"
                data-cy="training-description"
                [tooltip]="training.description"
                [showDelay]="250"
              >
                {{ training.description }}
              </div>
              <div
                class="min-w-[10rem] overflow-hidden text-ellipsis whitespace-nowrap border-b border-r p-2"
                data-cy="requested-training-method"
              >
                {{ training.method }}
              </div>
              <div
                class="w-[45rem] min-w-[10rem] overflow-hidden text-ellipsis whitespace-nowrap border-b border-r p-2"
                data-cy="training-reason"
                [tooltip]="training.reason"
                [showDelay]="250"
              >
                {{ training.reason }}
              </div>
              <div class="flex min-w-[10rem] flex-1 justify-center gap-8 border-b border-r p-2">
                <span *ngIf="training.state === ETrainingState.REQUESTED">Requested</span>
                <span
                  class="text-green-500"
                  data-cy="requested-training-approved"
                  *ngIf="training.state === ETrainingState.APPROVED"
                  >Approved</span
                >
                <span class="text-red-500" *ngIf="training.state === ETrainingState.REJECTED">Rejected</span>
              </div>
            </div>
          }
        </div>
      </div>
    }
  </div>
</div>
