import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ICoinTransfer } from '../models/coinTransfer.model';
import { IEmployee } from '../models/employee.model';
import { ILeave } from '../models/leave.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {}

  public editLeave(leave: ILeave): Observable<any> {
    return this.http.put(environment.baseUrl + `/leave/${leave._id}`, leave);
  }

  public getAllEmployees(tester = false): Observable<any> {
    return this.http.get(environment.baseUrl + '/employee?tester=' + tester);
  }

  public getTesterPassword(id: string): Observable<any> {
    return this.http.get(environment.baseUrl + `/employee/getTesterPassword/${id}`);
  }

  public addNewEmployee(employee: IEmployee): Observable<any> {
    return this.http.post(environment.baseUrl + '/employee', employee);
  }

  public editEmployee(employee: IEmployee): Observable<any> {
    return this.http.put(environment.baseUrl + `/employee/${employee._id}`, employee);
  }

  public editPersonalInformation(employee: IEmployee, oldPassword?: string, newPassword?: string): Observable<any> {
    return this.http.put(environment.baseUrl + `/employee/personalInfo/${employee._id}`, {
      employee,
      oldPassword,
      newPassword,
    });
  }

  public deleteEmployee(id: string): Observable<any> {
    return this.http.delete(environment.baseUrl + `/employee/${id}`);
  }

  public getNextBirthday(): Observable<any> {
    return this.http.get(environment.baseUrl + '/employee/nextbirthday');
  }

  public updateOvertime(employeeID: string, reason: string, timeAmount: number): Observable<any> {
    return this.http.post(environment.baseUrl + `/employee/changeOvertime`, {
      userId: this.authService.getCurrentEmployee()._id,
      employeeId: employeeID,
      description: reason,
      timeAmount: timeAmount,
    });
  }

  public updateCoins(transaction: ICoinTransfer): Observable<any> {
    return this.http.put(environment.baseUrl + `/employee/coins/${transaction.employee._id}`, transaction);
  }
}
