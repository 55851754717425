import { LeaveTimeType } from '../enum/leave.enum';

export interface ILeave {
  _id?: string;
  employeeId: string;
  employeeName: string;
  startDate: Date | string;
  endDate: Date | string;
  calendarStartDate: Date | string;
  calendarEndDate: Date | string;
  type: LeaveTimeType;
  sickNote?: string | File;
  isClosed?: boolean;
}

export interface LeaveEvent {
  id: string;
  key: number;
}

export class Leave implements ILeave {
  _id?: string;
  employeeId: string;
  employeeName: string;
  startDate: Date | string;
  endDate: Date | string;
  calendarStartDate: Date | string;
  calendarEndDate: Date | string;
  type: LeaveTimeType;
  sickNote?: string | File;
  isClosed?: boolean;

  constructor(leave: ILeave) {
    this._id = leave._id;
    this.employeeId = leave.employeeId;
    this.employeeName = leave.employeeName;
    this.startDate = new Date(leave.startDate);
    this.endDate = new Date(leave.endDate);
    this.calendarStartDate = new Date(leave.calendarStartDate);
    this.calendarEndDate = new Date(leave.calendarEndDate);
    this.type = leave.type;
    this.sickNote = leave.sickNote;
    this.isClosed = leave.isClosed;
  }
}
