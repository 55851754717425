import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent {
  public loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });

  private subscription = new Subscription();

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
  ) {}

  // logges in employee if login data is valid.
  public loginUser(): void {
    if (this.loginForm.invalid) {
      return;
    }
    this.subscription.add(
      this.authService.login(this.loginForm.get('email').value, this.loginForm.get('password').value).subscribe({
        next: (res) => {
          const employee = res['DATA'].employee;
          if (employee.firstLogin) {
            this.router.navigateByUrl('/personnel-file');
          } else {
            this.router.navigateByUrl('/dashboard');
          }
          this.toastr.success('You are logged in :)');
        },
        error: (err: any) => {
          if (err.error['DATA'] == 'Invalid credentials!' || err.error['DATA'] === 'No such email')
            this.toastr.error('Invalid username or password. Please try again');
        },
      }),
    );
  }

  public get email() {
    return this.loginForm.get('email');
  }

  public get password() {
    return this.loginForm.get('password');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
