<div class="flex flex-col bg-lightGrayish p-5 lg:gap-4" [ngClass]="{ 'mb-5': router.url.includes('/admin') }">
  <app-text-content-box [headline]="'Goals'" [headerIcon]="'🎯'"></app-text-content-box>
  @if (router.url.endsWith('/admin')) {
    <ng-content></ng-content>
  }

  @for (goal of allCompanyGoals; track goal) {
    <ul
      [ngClass]="{ 'border-b border-gray-200': router.url.includes('/admin') && !$last }"
      class="relative flex items-center justify-between gap-8"
      data-cy="goal-element"
    >
      <li class="w-full p-1 max-lg:pt-2">
        <span class="w-full max-lg:text-xs">{{ goal.description }}</span>
      </li>

      @if (router.url.endsWith('/admin')) {
        <img
          tooltip="Delete goal"
          [showDelay]="250"
          (click)="onDeleteCompanyGoal(goal)"
          class="h-5 cursor-pointer"
          data-cy="goal-delete-button"
          src="assets/img/icons/delete.png"
          alt="delete"
        />
      }

      @if (confirmDialog) {
        <app-confirm-dialog
          (closeDialog)="closeConfirmDialog()"
          (saveChanges)="deleteCompanyGoal(currentGoal._id)"
          [data]="dataToConfirm"
        ></app-confirm-dialog>
      }
    </ul>
  }
</div>
