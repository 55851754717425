import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IEmployee } from 'src/app/models/employee.model';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from 'src/app/services/helper.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { EClothingSize, EShoeSize } from 'src/app/enum/size.enum';
import { NgForm } from '@angular/forms';
import { cloneDeep, isEqual } from 'lodash';
import { EmployeeService } from 'src/app/services/employee.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-personnel-file',
  templateUrl: './personnel-file.component.html',
  styleUrl: './personnel-file.component.scss',
})
export class PersonnelFileComponent implements OnInit {
  @ViewChild('shoeSizeDropdown') shoeSizeDropdown;
  @ViewChild('clothingSizeDropdown') clothingSizeDropdown;

  public employeeForm: FormGroup;
  public passwordForm: FormGroup;

  public employee = {} as IEmployee;
  public clonedEmployee = {} as IEmployee;
  public clothingSizes = Object.values(EClothingSize);
  public shoeSizes = Object.values(EShoeSize);

  private subscriptions: Subscription = new Subscription();
  public birthday: string;

  constructor(
    private authService: AuthService,
    private employeeService: EmployeeService,
    private toastr: ToastrService,
    public helperService: HelperService,
    private router: Router,
    private fb: FormBuilder,
  ) {
    this.employee = this.authService.getCurrentEmployee();
    this.clonedEmployee = cloneDeep(this.employee);
  }

  ngOnInit(): void {
    this.initForms();
    this.birthday = this.formattDate(new Date(this.employee.birthdate));
  }

  private formattDate(date: Date): string {
    return date.toISOString().split('T')[0];
  }

  private initForms(): void {
    this.employeeForm = this.fb.group({
      firstname: [this.employee.firstname, Validators.required],
      lastname: [this.employee.lastname, Validators.required],
      address: [this.employee.address, [Validators.required, Validators.pattern(/\d+/)]],
      city: [this.employee.city, [Validators.required, Validators.pattern('^[a-zA-Z]+(?:,[a-zA-Z]+)*$')]],
      postcode: [this.employee.postcode, [Validators.required, Validators.pattern('^[0-9]{5}$')]],
      clothingSize: [this.employee.clothingSize, Validators.required],
      birthday: [this.employee.birthdate, Validators.required],
      shoeSize: [this.employee.shoeSize, Validators.required],
      iban: [
        this.employee.iban,
        [Validators.required, Validators.pattern(/DE[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){4}([0-9]{2})\s?/)],
      ],
    });

    this.passwordForm = this.fb.group(
      {
        oldPassword: [''],
        password: ['', [Validators.minLength(6), Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d$@!%*?&]+$/)]],
        confirmPassword: ['', []],
      },
      {
        validators: this.passwordMatchValidator,
      },
    );
  }

  public onSubmit(): void {
    if (this.employee.firstLogin && this.passwordForm.value.password.length === 0) {
      this.toastr.error('You have to set a new password on first login');
      return;
    }
    if (this.employeeForm.invalid || this.passwordForm.invalid) {
      this.toastr.error('Form is invalid, Please fill all fields');
      return;
    }
    if (isEqual(this.employee, this.clonedEmployee) && !this.passwordForm.value.password) {
      this.toastr.success('No changes were made');
      return;
    }

    this.employeeService
      .editPersonalInformation(this.employee, this.passwordForm.value.oldPassword, this.passwordForm.value.password)
      .subscribe({
        next: (res) => {
          this.authService.setCurrentEmployee(res.DATA);
          this.toastr.success('Your personal information has been updated');
          this.passwordForm.reset();
          if (this.employee.firstLogin) {
            this.router.navigate(['/dashboard']);
          }
        },
        error: (err) => {
          this.toastr.error(err.error.DATA);
        },
      });
  }

  public onSelectClothingSize(size: EClothingSize) {
    this.employeeForm.get('clothingSize').setValue(size);
    this.employee.clothingSize = size;
  }

  public onSelectShoeSize(size: EShoeSize) {
    this.employeeForm.get('shoeSize').setValue(size);
    this.employee.shoeSize = size;
  }

  public onSelectBirthday(event: Event) {
    const birthday = this.helperService.dateToISOString(event);
    this.employee.birthdate = birthday;

    this.employeeForm.get('birthday').setValue((event.target as HTMLInputElement).value);
  }

  public closeAllDropdowns(dropdown: string) {
    if (dropdown !== 'shoeSizeDropdown' && this.shoeSizeDropdown) {
      this.shoeSizeDropdown.showDropdown = false;
    }
    if (dropdown !== 'clothingSizeDropdown' && this.clothingSizeDropdown) {
      this.clothingSizeDropdown.showDropdown = false;
    }
  }

  private passwordMatchValidator(group: AbstractControl): ValidationErrors | null {
    const password = group.get('password')?.value;
    const confirmPassword = group.get('confirmPassword')?.value;

    return password === confirmPassword ? null : { notMatching: true };
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
