import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { IEmployee } from 'src/app/models/employee.model';
import { IProduct } from 'src/app/models/product.model';
import { ITransaction } from 'src/app/models/transaction.model';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { ProductService } from 'src/app/services/product.service';
import { TransactionService } from 'src/app/services/transaction.service';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrl: './shop.component.scss',
})
export class ShopComponent {
  private subscription = new Subscription();
  public paymentHistory = [] as ITransaction[];
  public products = [] as IProduct[];

  public showAddProductDialog = false;
  public currentUser: IEmployee;

  constructor(
    private transactionService: TransactionService,
    private productService: ProductService,
    private authService: AuthService,
    private helperService: HelperService,
    private toastService: ToastrService,
  ) {
    this.currentUser = this.authService.getCurrentEmployee();
    this.getLatestTransactions();
    this.getAllProducts();
  }

  public getLatestTransactions(): void {
    this.subscription.add(
      this.transactionService.getLatestTransactions().subscribe({
        next: (res) => {
          this.paymentHistory = res.DATA;
        },
        error: () => {
          this.toastService.error('Something went wrong');
        },
      }),
    );
  }

  private getAllProducts(): void {
    this.subscription.add(
      this.productService.getAllProducts().subscribe({
        next: (res) => {
          this.products = res.DATA;
          this.sortProductsByPrice();
        },
        error: () => {
          this.toastService.error('Something went wrong');
        },
      }),
    );
  }

  public sortProductsByPrice(): void {
    this.products = this.products.sort((a: IProduct, b: IProduct) => a.price - b.price);
  }

  public openAddProductDialog(): void {
    this.showAddProductDialog = true;
  }

  public onAddNewProduct(product: IProduct): void {
    this.subscription.add(
      this.productService.addNewProduct(product).subscribe({
        next: (res) => {
          this.toastService.success(res.MESSAGE);
          this.showAddProductDialog = false;
          this.getAllProducts();
          this.sortProductsByPrice();
        },
        error: () => {
          this.toastService.error('Something went wrong');
        },
      }),
    );
  }

  public editProduct(product: IProduct): void {
    this.subscription.add(
      this.productService.updateProduct(product).subscribe({
        next: () => {
          this.toastService.success('Product was updated successfully');
          this.helperService.userCoinsChanged$.next(true);
          this.getAllProducts();
        },
        error: (err) => {
          this.toastService.error(err.error.MESSAGE);
        },
      }),
    );
  }

  public deleteProduct(product: IProduct): void {
    this.subscription.add(
      this.productService.deleteProduct(product._id).subscribe({
        next: () => {
          this.toastService.success('Product was deleted successfully');
          this.products = this.products.filter((prod) => prod._id !== product._id);
        },
        error: (err) => {
          this.toastService.error(err.error.MESSAGE);
        },
      }),
    );
  }

  public onBuyProduct(product: IProduct): void {
    this.subscription.add(
      this.transactionService.createTransaction(product).subscribe({
        next: () => {
          this.toastService.success('Congrats! Product was added successfully');
          this.helperService.userCoinsChanged$.next(true);
        },
        error: (err) => {
          this.toastService.error(err.error.MESSAGE);
        },
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
