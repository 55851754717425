<div class="flex w-full flex-col items-center justify-center gap-5">
  <div class="flex w-full max-w-7xl gap-5 max-lg:flex-col">
    <div class="mb-5 flex h-min w-full flex-col gap-4 bg-lightGrayish p-5 lg:gap-7">
      <div class="relative flex justify-between">
        <app-text-content-box [headline]="'Current Books'" [headerIcon]="'📖'"></app-text-content-box>
        <div class="relative">
          <div
            class="absolute right-0 h-12 w-36 cursor-pointer max-lg:top-11 max-smC:w-[6.5rem] max-smC:text-sm lg:w-40"
            (focusout)="showBookDropdown = false"
            tabindex="0"
          >
            <div
              (click)="onToggleDropDown('book')"
              class="text-mainGreen flex h-12 items-center bg-white px-3 py-2 text-[#E6AE48]"
            >
              <span class="truncate pr-2">Add Element</span>
              <fa-icon
                [icon]="dropDownIcon"
                class="absolute right-2 -mb-1"
                data-cy="add-element-dropdown"
                [ngClass]="{ '-mb-0 rotate-180': showBookDropdown }"
              >
              </fa-icon>
            </div>

            @if (showBookDropdown) {
              <ul
                [ngClass]="{ 'opacity-0': !showBookDropdown, 'opacity-100': showBookDropdown }"
                class="absolute right-0 z-50 -mt-3 flex w-full flex-col gap-2 bg-white p-2 opacity-0 transition-all duration-500"
              >
                <li
                  (click)="manageLibraryDialogHandler($event)"
                  class="flex cursor-pointer items-center justify-between px-1 hover:text-[#E6AE48]"
                  data-cy="add-book-selection"
                >
                  <span class="truncate">Book</span>
                </li>
                <li
                  (click)="manageLibraryDialogHandler($event)"
                  class="flex cursor-pointer items-center justify-between px-1 hover:text-[#E6AE48]"
                  data-cy="add-suggestion-selection"
                >
                  <span class="truncate">Suggestion</span>
                </li>
              </ul>
            }
          </div>
        </div>
      </div>
      <div
        class="relative h-12 w-36 cursor-pointer max-smC:w-[6.5rem] max-smC:text-sm lg:w-40"
        (focusout)="showSortDropdown = false"
        tabindex="0"
      >
        <div (click)="onToggleDropDown('sort')" class="text-mainGreen flex h-12 items-center bg-white px-3 py-2">
          <span class="truncate pr-2">{{ selectedSortCategory }}</span>
          <fa-icon
            [icon]="dropDownIcon"
            class="absolute right-2 -mb-1"
            [ngClass]="{ '-mb-0 rotate-180': showSortDropdown }"
          >
          </fa-icon>
        </div>

        @if (showSortDropdown) {
          <ul
            [ngClass]="{ 'opacity-0': !showSortDropdown, 'opacity-100': showSortDropdown }"
            class="absolute right-0 z-50 -mt-3 flex w-full flex-col gap-2 bg-white p-2 opacity-0 transition-all duration-500"
          >
            <li
              (click)="onSortCategory('All Categories')"
              class="flex cursor-pointer items-center justify-between px-1 hover:text-[#E6AE48]"
            >
              <span class="truncate">All Categories</span>
            </li>
            <li
              (click)="onSortCategory('IT & Architecture')"
              class="flex cursor-pointer items-center justify-between px-1 hover:text-[#E6AE48]"
            >
              <span class="truncate">IT & Architecture</span>
            </li>
            <li
              (click)="onSortCategory('Coding')"
              class="flex cursor-pointer items-center justify-between px-1 hover:text-[#E6AE48]"
            >
              <span class="truncate">Coding</span>
            </li>
            <li
              (click)="onSortCategory('Tools')"
              class="flex cursor-pointer items-center justify-between px-1 hover:text-[#E6AE48]"
            >
              <span class="truncate">Tools</span>
            </li>
            <li
              (click)="onSortCategory('Business')"
              class="flex cursor-pointer items-center justify-between px-1 hover:text-[#E6AE48]"
            >
              <span class="truncate">Business</span>
            </li>
            <li
              (click)="onSortCategory('Misc. (Other)')"
              class="flex cursor-pointer items-center justify-between px-1 hover:text-[#E6AE48]"
            >
              <span class="truncate">Misc. (Other)</span>
            </li>
          </ul>
        }
      </div>
      @if (allAvailableBooks.length < 1) {
        <span class="text-xs lg:whitespace-nowrap lg:text-sm">There are currently no books to borrow</span>
      }
      @if (filteredBooks?.length < 1 && allAvailableBooks.length > 1) {
        <span class="text-xs lg:whitespace-nowrap lg:text-sm"> No books found in this category. </span>
      }
      @for (book of filteredBooks; track book; let i = $index) {
        <div
          class="relative flex w-full flex-col items-center border-2 border-sunflowerGold bg-paleOrange p-3 max-lg:pt-5 lg:p-5"
          data-cy="book-card"
        >
          <div class="flex w-full gap-4 pb-4">
            <input
              class="block h-12 w-full truncate p-2.5 text-sm placeholder-veryTransparentBlack placeholder:font-semibold"
              data-cy="book-elem-title"
              [value]="book.title"
              type="text"
              readonly
            />
            <input
              class="block h-12 w-full truncate p-2.5 text-sm placeholder-veryTransparentBlack placeholder:font-semibold"
              data-cy="book-elem-autor"
              [value]="book.author"
              type="text"
              readonly
            />
          </div>
          <div class="flex w-full gap-4 pb-4">
            <input
              class="block h-12 w-full truncate p-2.5 text-sm placeholder-veryTransparentBlack placeholder:font-semibold"
              data-cy="book-elem-category"
              [value]="book.category"
              type="text"
              readonly
            />
            <input
              class="block h-12 w-full truncate p-2.5 text-sm placeholder-veryTransparentBlack placeholder:font-semibold"
              data-cy="book-elem-type"
              [value]="'🏷️ ' + book.type"
              type="text"
              readonly
            />
          </div>
          <div class="flex w-full justify-end gap-4">
            @if (book.url && book.type === Type.eBook) {
              <button>
                <a [href]="book.url" target="_blank" data-cy="book-elem-url">
                  <fa-icon
                    tooltip="Open E-book"
                    [showDelay]="250"
                    [ngClass]="{ '-mr-[0px]': !isUserAllowedToEdit(book) }"
                    class="-mr-[2px] flex scale-90 cursor-pointer max-smC:h-3"
                    [icon]="faLink"
                  ></fa-icon>
                </a>
              </button>
            }
            @if (isUserAllowedToEdit(book)) {
              <img
                tooltip="Edit book"
                [showDelay]="250"
                value="edit"
                (click)="manageLibraryDialogHandler($event, book._id, 'available')"
                class="h-4 cursor-pointer max-smC:h-3"
                src="../../../assets/img/icons/edit.png"
                alt="edit"
              />
            }
            @if (isUserAllowedToEdit(book)) {
              <img
                tooltip="Delete book"
                [showDelay]="250"
                value="delete"
                (click)="manageLibraryDialogHandler($event, book._id, 'available')"
                class="h-4 cursor-pointer max-smC:h-3"
                src="../../../assets/img/icons/delete.png"
                alt="delete"
              />
            }
          </div>

          <div class="relative w-full pt-2">
            <div class="flex flex-col gap-2 max-smC:text-xs lg:w-3/5">
              <div class="flex w-full gap-2 max-smC:gap-1 lg:gap-5" data-cy="book-elem-current-holder">
                <span class="whitespace-nowrap">Current Holder</span>
                <span class="truncate whitespace-nowrap"
                  >| {{ book.currentHolderId.firstname + ' ' + book.currentHolderId.lastname?.charAt(0) }}.</span
                >
              </div>
              <div class="flex w-full gap-[4.55rem] max-smC:gap-[3.3rem] lg:gap-[5.3rem]" data-cy="book-elem-owner">
                <span class="whitespace-nowrap text-veryFaintBlack">Owner</span>
                <span class="truncate whitespace-nowrap text-veryFaintBlack"
                  >|
                  {{
                    book.ownerId ? book.ownerId.firstname + ' ' + book.ownerId.lastname?.charAt(0) : 'No Owner'
                  }}.</span
                >
              </div>
            </div>
            <!-- Temporary  -->
            @if (
              isRequestButtonVisible(book) ||
              (book.currentHolderId?._id !== book?.ownerId?._id &&
                book.currentHolderId?._id !== authService.getCurrentEmployee()?._id &&
                book?.ownerId?._id !== authService.getCurrentEmployee()?._id &&
                book.requestedBy?._id !== authService.getCurrentEmployee()?._id &&
                book.state === 'Available')
            ) {
              <button
                (click)="onSendBorrowBookRequestClick(book)"
                class="bottom-0 right-0 flex h-11 w-36 items-center justify-center border border-black bg-almostBlack text-xs text-sunflowerGold max-lg:mt-4 max-lg:w-full lg:absolute"
              >
                Borrow this Book
              </button>
            }
            @if (
              (book.requestedBy &&
                authService.getCurrentEmployee()._id !== book.currentHolderId._id &&
                book.requestedBy._id !== book.currentHolderId._id) ||
              (book.requestedBy?._id === authService.getCurrentEmployee()._id &&
                book.currentHolderId._id !== book.requestedBy?._id)
            ) {
              <button
                disabled
                class="bottom-0 right-0 flex h-11 w-36 items-center justify-center border border-black bg-almostBlack text-xs text-sunflowerGold opacity-80 max-lg:mt-4 max-lg:w-full lg:absolute"
              >
                Pending
              </button>
            }
            @if (
              (book.requestedBy?._id === book.currentHolderId?._id &&
                book.currentHolderId?._id === authService.getCurrentEmployee()?._id) ||
              (!book.requestedBy && book.currentHolderId?._id === authService.getCurrentEmployee()?._id)
            ) {
              <button
                class="bottom-0 right-0 flex h-11 w-36 items-center justify-center border border-black bg-almostBlack text-xs text-sunflowerGold opacity-80 max-lg:mt-4 max-lg:w-full lg:absolute"
                disabled
              >
                Owned
              </button>
            }
            @if (
              book.requestedBy &&
              book.requestedBy?._id !== authService.getCurrentEmployee()?._id &&
              authService.getCurrentEmployee()?._id === book.currentHolderId?._id
            ) {
              <button
                (click)="onLendBookClick(book)"
                class="bottom-0 right-0 flex h-11 w-36 items-center justify-center border border-[#E6AE48] bg-[#E6AE48] text-xs text-white max-lg:mt-4 max-lg:w-full lg:absolute"
              >
                Accept request
              </button>
            }
            <!-- Temporary end-->
          </div>
        </div>
      }
    </div>
    <div class="mb-5 flex h-min flex-col gap-2 bg-lightGrayish p-5 lg:w-3/6">
      <app-text-content-box
        [headline]="'Purchase Suggestions'"
        [headerIcon]="'📖'"
        class="whitespace-nowrap pb-2"
      ></app-text-content-box>
      @if (allBooksSuggestions.length < 1) {
        <span class="text-xs lg:whitespace-nowrap lg:text-sm">There are currently no purchase suggestions</span>
      }
      @for (book of allBooksSuggestions; track book; let i = $index) {
        <div class="flex flex-col items-center" data-cy="suggestion-card">
          @if (isUserAllowedToEdit(book)) {
            <button (click)="manageLibraryDialogHandler($event, book._id, 'suggestion')" class="-mb-3 flex w-6">
              <img class="z-10" src="../../../assets/img/icons/solve.png" alt="delete" />
            </button>
          }
          <div
            class="relative flex w-full flex-col items-center border-2 border-sunflowerGold bg-paleOrange p-3 max-lg:pt-5 max-smC:text-xs lg:p-5"
          >
            <input
              [value]="book.title"
              class="block h-8 w-full truncate p-2.5 text-sm placeholder-veryTransparentBlack placeholder:font-semibold"
              data-cy="suggestion-title"
              type="text"
              readonly
            />
            <div class="flex w-full gap-2 pt-2">
              <input
                [value]="book.category"
                class="block h-8 w-full truncate p-2.5 text-sm placeholder-veryTransparentBlack placeholder:font-semibold"
                data-cy="suggestion-category"
                type="text"
                readonly
              />
              <input
                [value]="book.author"
                class="block h-8 w-full truncate p-2.5 text-sm placeholder-veryTransparentBlack placeholder:font-semibold"
                data-cy="suggestion-author"
                type="text"
                readonly
              />
            </div>
            <div class="relative flex w-full items-center justify-center pt-5">
              <span class="absolute left-0 text-xs lg:bottom-1" data-cy="suggestion-type">{{ '🏷️ ' + book.type }}</span>
              <div class="ml-2 flex gap-1">
                <p class="font-bold lg:text-xl" data-cy="suggestion-upvotes">{{ book.upvotes }}</p>
                <button (click)="proofAndSetVote(book._id, book)">
                  <img class="h-4 lg:h-5" src="../../../assets/img/icons/arrow-up.png" alt="Arrow up" />
                </button>
              </div>
              @if (book.url && book.type === Type.eBook) {
                <button>
                  <a [href]="book.url" target="_blank" data-cy="suggestion-url">
                    <fa-icon
                      class="absolute bottom-1 right-6 flex scale-75 cursor-pointer max-smC:bottom-[2px] max-smC:scale-100"
                      [ngClass]="{ '!right-0': !isUserAllowedToEdit(book) }"
                      [icon]="faLink"
                    ></fa-icon>
                  </a>
                </button>
              }
              @if (isUserAllowedToEdit(book)) {
                <img
                  tooltip="Edit book"
                  [showDelay]="250"
                  value="edit"
                  (click)="manageLibraryDialogHandler($event, book._id, 'suggestion')"
                  class="absolute right-0 h-4 cursor-pointer lg:bottom-1"
                  src="../../../assets/img/icons/edit.png"
                  alt="edit"
                />
              }
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</div>
@if (showLibraryDialog) {
  <app-manage-library-dialog
    (closeDialog)="toggleLibraryDialog()"
    (createNewBook)="createNewBookCard($event)"
    (updateBook)="updateBookCard($event)"
    [dialogData]="dataToConfirm"
    [booksDataEdit]="selectedBook"
  ></app-manage-library-dialog>
}
@if (confirmDialog) {
  <app-confirm-dialog
    (closeDialog)="confirmDialog = false"
    (saveChanges)="deleteBooks(selectedBook._id, $event)"
    [data]="dataToConfirm"
  ></app-confirm-dialog>
}
@if (showConfirmationDialog) {
  <app-confirmation-dialog
    (acceptChanges)="onAcceptConfirmationDialog($event)"
    (closeDialog)="showConfirmationDialog = false"
    [onAcceptButtonText]="acceptButtonText"
    [data]="bookRequestData"
  ></app-confirmation-dialog>
}
