import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { LeaveService } from 'src/app/services/leave.service';
import { LeaveTimeType } from 'src/app/enum/leave.enum';
import { ILeave, Leave } from 'src/app/models/leave.model';
import { IEmployee } from 'src/app/models/employee.model';
import { HelperService } from 'src/app/services/helper.service';
import { IConfirmDialogData } from 'src/app/models/confirm-data';
import { ILeaveDialog } from 'src/app/models/leave-dialog.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-sick-leaves',
  templateUrl: './admin-sick-leaves.component.html',
  styleUrl: './admin-sick-leaves.component.scss',
})
export class AdminSickLeavesComponent {
  @Input() employees: IEmployee[] = [];
  public showPastSickleaves: boolean = false;
  public sickLeaves: Leave[] = [];
  public selectedEmployee: IEmployee = {} as IEmployee;
  public startDate: string = '';
  public endDate: string = '';
  public dragEvent: any;
  public fileName: string = '';
  public confirmLeaveData = {} as IConfirmDialogData;
  public showConfirmCloseDialog = false;
  public showConfirmDeleteDialog = false;
  public showEditLeaveDialog = false;
  public leaveDialog = {} as ILeaveDialog;
  public leaveData = {} as ILeave;
  public leaveType = LeaveTimeType.SICK;

  constructor(
    private leaveService: LeaveService,
    public helperService: HelperService,
    private toastService: ToastrService,
  ) {}

  public togglePastSickLeages(): void {
    this.showPastSickleaves = !this.showPastSickleaves;
    // this.getAllTrainings(this.showIsRequested);
  }

  public onSelectEmployee(employee): void {
    this.selectedEmployee = employee;
    // this.onToggleDropDown();
  }

  public createSickLeave(leave: Leave): void {
    const newLeave = {
      ...leave,
      startDate: new Date(leave.startDate).toISOString(),
      endDate: new Date(leave.endDate).toISOString(),
      type: LeaveTimeType.SICK,
      employeeName: `${leave.employeeName}`,
      sickNote: leave.sickNote,
      closed: false,
      calendarStartDate: new Date(leave.startDate).toISOString(),
      calendarEndDate: new Date(leave.endDate).toISOString(),
    } as Leave;

    this.leaveService.addLeave(newLeave).subscribe({
      next: () => {
        this.clearInputFields();
        this.getAllSickLeaves();
        this.closeLeaveDialog();
      },
      error: (err) => {
        console.error('ERROR: ' + err);
        console.error(err);
      },
    });
  }

  public onSelectStartDate(event: Event): void {
    this.startDate = this.helperService.dateToISOString(event);
  }

  public onSelectEndDate(event: Event): void {
    this.endDate = this.helperService.dateToISOString(event);
  }

  ngOnInit(): void {
    this.getAllSickLeaves();
  }

  public getAllSickLeaves(): void {
    this.leaveService.getAllLeaves(LeaveTimeType.SICK).subscribe({
      next: (res) => {
        this.sickLeaves = res.DATA;
      },
      error: (err) => {
        console.error('ERROR: ' + err);
        console.error(err);
      },
    });
  }

  public sickLeavesAvailable(): boolean {
    if (this.sickLeaves && this.sickLeaves.length) {
      if (this.showPastSickleaves) {
        return this.sickLeaves.some((sickLeave) => sickLeave.isClosed);
      } else {
        return this.sickLeaves.some((sickLeave) => !sickLeave.isClosed);
      }
    }
    return false;
  }

  public getAmountSickDays(sickLeave: Leave): number {
    // Create date objects for the start and end dates of the sick leave
    const startDate = new Date(sickLeave.startDate);
    const endDate = new Date(sickLeave.endDate);

    // Calculate the difference in milliseconds between the start and end dates
    const diffTime = endDate.getTime() - startDate.getTime();

    // Calculate the difference in days between the start and end dates
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    // If the difference in days is 0, return 1, otherwise return the difference in days plus one
    // -> Start & end date = same day should be 1 day.
    return diffDays === 0 ? 1 : diffDays + 1;
  }

  public getSickLeaveDate(date: string | Date): string {
    const dateObject = typeof date === 'string' ? new Date(date) : date;
    return dateObject.toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' });
  }

  public deleteLeave(leave: Leave): void {
    this.leaveService.deleteLeave(leave).subscribe({
      next: (res) => {
        this.getAllSickLeaves();
        this.closeDeleteLeaveDialog();
      },
      error: (err) => {
        console.error('ERROR: ' + err);
        console.error(err);
      },
    });
  }

  private clearInputFields(): void {
    this.fileName = '';
    this.selectedEmployee = {} as IEmployee;
    this.startDate = '';
    this.endDate = '';
    /*     this.base64ToBlob = null; */
    this.leaveData = {} as ILeave;
  }

  public openCloseConfirmDialog(leave: ILeave): void {
    if (
      leave.isClosed === false &&
      this.getAmountSickDays(leave) > 3 &&
      (leave.sickNote === undefined || leave.sickNote === null || leave.sickNote === '')
    ) {
      this.toastService.error('Can not be closed: Sick note missing!');
      return;
    }
    if (leave.isClosed) {
      this.confirmLeaveData.headline = 'Want to reopen this leave?';
    } else {
      this.confirmLeaveData.headline = 'Want to close this leave?';
    }
    this.confirmLeaveData.leave = leave;
    this.confirmLeaveData.leave.startDate = new Date(leave.startDate);
    this.confirmLeaveData.leave.endDate = new Date(leave.endDate);
    this.showConfirmCloseDialog = true;
  }

  public openDeleteConfirmDialog(leave: ILeave): void {
    this.confirmLeaveData.headline = 'Want to delete this leave?';
    this.confirmLeaveData.leave = leave;
    this.showConfirmDeleteDialog = true;
  }

  public openEditSickLeaveDialog(leave: ILeave): void {
    this.leaveDialog.headline = 'Edit Sick Leave';
    this.leaveDialog.type = 'edit';
    this.leaveData = leave;
    this.leaveData.startDate = new Date(leave.startDate);
    this.leaveData.endDate = new Date(leave.endDate);
    this.showEditLeaveDialog = true;
  }

  public openAddSickLeaveDialog(): void {
    this.leaveDialog.headline = 'Add New Sick Leave';
    this.leaveDialog.type = 'add';
    this.showEditLeaveDialog = true;
  }

  public closeConfirmDialog(): void {
    this.showConfirmCloseDialog = false;
  }

  public closeLeaveDialog(): void {
    this.showEditLeaveDialog = false;
    this.clearInputFields();
  }

  public closeDeleteLeaveDialog(): void {
    this.showConfirmDeleteDialog = false;
  }

  public onConfirmCloseLeave(data: IConfirmDialogData): void {
    const leave = data.leave;
    leave.isClosed = !leave.isClosed;
    this.updateLeave(leave);
  }

  public onConfirmDeleteLeave(data: IConfirmDialogData): void {
    const leave = data.leave;
    this.deleteLeave(leave);
  }

  public updateLeave(leave: ILeave): void {
    this.leaveService.updateLeave(leave).subscribe({
      next: (res) => {
        this.getAllSickLeaves();
        this.closeLeaveDialog();
      },
      error: (err) => {
        console.error('ERROR: ' + err);
        console.error(err);
      },
    });
  }

  public setLeaveDialogAction(leave: ILeave, action: 'delete' | 'edit' | 'add'): void {
    if (!leave.startDate || !leave.endDate) {
      this.toastService.error('Start date or end date is invalid');
      return;
    }
    let employee = this.employees.find((employee) => employee._id === leave.employeeId);
    if (!employee) {
      this.toastService.error('Employee not found');
      return;
    }

    if (action === 'edit') {
      this.editLeave(leave);
    }
    if (action === 'add') {
      leave.type = LeaveTimeType.SICK;
      this.createSickLeave(leave);
    }
    if (action === 'delete') {
      this.deleteLeave(leave);
    }
  }

  public editLeave(leave: ILeave): void {
    this.leaveService.updateLeave(leave).subscribe({
      next: () => {
        this.toastService.success('Leave was successfully edited');
        this.closeLeaveDialog();
        this.getAllSickLeaves();
      },
      error: () => {
        this.toastService.error('Something went wrong');
      },
    });
  }

  public viewFile(leave: ILeave): void {
    this.leaveService.getLeavesPdf(leave.sickNote as string).subscribe({
      next: (res) => {
        const url = window.URL.createObjectURL(res);
        const a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        a.click();
        window.URL.revokeObjectURL(url);
      },
      error: (err) => {
        this.toastService.error(err.error.MESSAGE);
      },
    });

    /*    const fileBase64 = leave.sickNote; // Base64 string of the file
    const fileType = 'application/pdf'; // Adjust this MIME type based on the file type you're working with

    // Create a new Blob object with the correct MIME type
    const blob = this.base64ToBlob(fileBase64, fileType);
    const blobUrl = URL.createObjectURL(blob);

    // Open the file in a new tab
    const link = document.createElement('a');
    link.href = blobUrl;
    link.target = '_blank';
    link.click();

    // Revoke the object URL to release memory
    URL.revokeObjectURL(blobUrl); */
  }

  // Helper method to convert base64 to Blob
  /*   private base64ToBlob(base64: string, contentType: string): Blob {
    const byteCharacters = atob(base64.split(',')[1]); // Decode base64
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  } */
}
