<div
  class="min-w-screen animated fadeIn faster fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center bg-cover bg-center bg-no-repeat outline-none focus:outline-none"
  id="modal-id"
>
  <div class="absolute inset-0 z-0 bg-black opacity-80"></div>
  <div
    class="relative m-auto max-h-[90vh] w-full overflow-auto bg-lightGrayish px-14 pb-7 pt-14 shadow-lg lg:max-w-[800px] lg:rounded-xl"
    data-cy="edit-employee-dialog"
  >
    <div class="mb-6 grid gap-4 lg:grid-cols-2">
      <div>
        <label class="mb-2 block text-sm font-medium">First Name</label>
        <input
          type="text"
          class="block h-12 w-full border border-gray-300 bg-white p-2.5 text-sm"
          data-cy="employee-firstname"
          required
          [(ngModel)]="employee.firstname"
        />
      </div>
      <div>
        <label class="mb-2 block text-sm font-medium">Last Name</label>
        <input
          type="text"
          class="block h-12 w-full border border-gray-300 bg-white p-2.5 text-sm"
          data-cy="employee-lastname"
          required
          [(ngModel)]="employee.lastname"
        />
      </div>
      <div>
        <label class="mb-2 block text-sm font-medium">Email</label>
        <input
          type="email"
          class="block h-12 w-full border border-gray-300 bg-white p-2.5 text-sm"
          data-cy="employee-email"
          required
          [(ngModel)]="employee.email"
        />
      </div>
      <div [ngClass]="{ 'pointer-events-none opacity-50': employee.role === EmployeeRole.TESTER }">
        <label class="mb-2 block text-sm font-medium">Clockify API key</label>
        <input
          type="text"
          class="block h-12 w-full border border-gray-300 bg-white p-2.5 text-sm"
          data-cy="employee-api-key"
          required
          [(ngModel)]="employee.clockifyApiKey"
        />
      </div>

      <form [formGroup]="timeForm">
        <label class="color-black mb-2 block text-sm font-medium">Overtime Balance</label>
        <input
          placeholder="HH:MM"
          [disabled]="data.type === 'edit'"
          [readOnly]="data.type === 'edit'"
          [ngClass]="{ inputDisabled: data.type === 'edit' }"
          class="block h-12 w-full border border-gray-300 bg-white p-2.5 text-sm"
          data-cy="employee-overtime-balance"
          formControlName="timeInput"
          (input)="helperService.onTimeInput($event, timeForm)"
        />
      </form>

      <div>
        <label class="mb-2 block text-sm font-medium">Vacation Days</label>
        <input
          type="number"
          [disabled]="data.type === 'edit'"
          [readOnly]="data.type === 'edit'"
          [ngClass]="{ inputDisabled: data.type === 'edit' }"
          class="block h-12 w-full border border-gray-300 bg-white p-2.5 text-sm"
          data-cy="employee-vacation-days"
          [(ngModel)]="employee.vacationDays"
        />
      </div>
      <div>
        <label class="mb-2 block text-sm font-medium">Weekly Hours</label>
        <input
          type="number"
          maxlength="2"
          class="block h-12 w-full border border-gray-300 bg-white p-2.5 text-sm"
          data-cy="employee-weekly-hours"
          [(ngModel)]="employee.weeklyHours"
        />
      </div>

      <div data-cy="employee-role-column">
        <label class="mb-2 block text-sm font-medium">Employee Role</label>
        @if (data.type === 'edit' && employee.role === EmployeeRole.TESTER) {
          <div class="flex h-12 w-full items-center border border-gray-300 bg-white p-2.5 text-sm">
            <span>Tester</span>
          </div>
        } @else {
          <app-dropdown
            #employeeRoleDropdown
            (click)="closeAllDropdowns('employeeRoleDropdown')"
            [elements]="getEmployeeRoles()"
            placeholder="Choose role"
            [selectedElement]="employee.role"
            (selectElement)="onSelectEmployeeRole($event)"
          ></app-dropdown>
        }
      </div>
    </div>
    <hr />
    <div class="mb-6 grid gap-4 pt-4 lg:grid-cols-2">
      <div>
        <label class="mb-2 block text-sm font-medium">Address</label>
        <input
          type="text"
          class="block h-12 w-full border border-gray-300 bg-white p-2.5 text-sm"
          data-cy="employee-address"
          [(ngModel)]="employee.address"
        />
      </div>
      <div>
        <label class="mb-2 block text-sm font-medium">City</label>
        <input
          type="text"
          class="block h-12 w-full border border-gray-300 bg-white p-2.5 text-sm"
          data-cy="employee-city"
          [(ngModel)]="employee.city"
        />
      </div>
      <div>
        <label class="mb-2 block text-sm font-medium">Postcode</label>
        <input
          type="number"
          class="remove-arrow block h-12 w-full border border-gray-300 bg-white p-2.5 text-sm"
          data-cy="employee-postcode"
          [(ngModel)]="employee.postcode"
        />
      </div>
      <div></div>
    </div>
    <hr />
    <div class="mb-6 grid gap-4 pt-4 lg:grid-cols-2">
      <div data-cy="clothing-size-column">
        <label class="mb-2 block text-sm font-medium">Clothing Size</label>
        <app-dropdown
          #clothingSizeDropdown
          (click)="closeAllDropdowns('clothingSizeDropdown')"
          [elements]="clothingSizes"
          placeholder="Choose size"
          [selectedElement]="employee.clothingSize"
          (selectElement)="onSelectClothingSize($event)"
        ></app-dropdown>
      </div>
      <div data-cy="shoe-size-column">
        <label class="mb-2 block text-sm font-medium">Shoe Size</label>
        <app-dropdown
          #shoeSizeDropdown
          (click)="closeAllDropdowns('shoeSizeDropdown')"
          [elements]="shoeSizes"
          placeholder="Choose size"
          [selectedElement]="employee.shoeSize"
          (selectElement)="onSelectShoeSize($event)"
        ></app-dropdown>
      </div>
    </div>
    <hr />
    <div class="mb-6 grid gap-4 pt-4 lg:grid-cols-2">
      <div data-cy="employee-birthday-container">
        <label class="mb-2 block text-sm font-medium">Birthday</label>
        <input
          type="text"
          mwlFlatpickr
          class="block h-12 w-full border border-gray-300 bg-white p-2.5 text-sm"
          data-cy="employee-birthday"
          placeholder="Date of Birthday"
          (change)="onSelectBirthday($event)"
          required
          [altInput]="true"
          [ngModel]="employee.birthdate"
          [convertModelValue]="true"
          [options]="helperService.datePickerOptions"
        />
      </div>
      <div data-cy="employee-firstday-container">
        <label class="mb-2 block text-sm font-medium">First Day at 42</label>
        <input
          type="text"
          mwlFlatpickr
          class="block h-12 w-full border border-gray-300 bg-white p-2.5 text-sm"
          data-cy="employee-firstday"
          placeholder="Start date"
          (change)="onSelectFirstDay($event)"
          [ngModel]="employee.joinedCompany"
          required
          [altInput]="true"
          [convertModelValue]="true"
          [options]="helperService.datePickerOptions"
        />
      </div>
    </div>
    <hr />
    <div class="mb-6 grid gap-4 pt-4 lg:grid-cols-2">
      <div>
        <label class="mb-2 block text-sm font-medium">IBAN</label>
        <input
          type="text"
          class="remove-arrow block h-12 w-full border border-gray-300 bg-white p-2.5 text-sm"
          data-cy="employee-iban"
          [(ngModel)]="employee.iban"
          name="iban"
        />
        <!--  <div *ngIf="employeeForm.get('iban').invalid">
          <small *ngIf="employeeForm.get('iban').errors?.['required']" class="text-red-600">IBAN is required.</small>
          <small *ngIf="employeeForm.get('iban').errors?.['pattern']" class="text-red-600"
            >Please enter a valid IBAN.</small
          >
        </div> -->
      </div>
    </div>
    <div class="flex items-end justify-around gap-1">
      <div class="flex flex-col items-center justify-center">
        <label class="mb-2 block text-sm font-medium">Admin</label>
        <input
          type="checkbox"
          [(ngModel)]="employee.isAdmin"
          class="h-5 w-9 appearance-none rounded-full bg-gray-300 shadow-inner transition-all duration-300 before:ml-0.5 before:inline-block before:h-4 before:w-4 before:rounded-full before:bg-sunflowerGold checked:bg-yellow-200 checked:before:translate-x-full focus:outline-none"
          data-cy="employee-admin"
        />
      </div>
      @if (employee.role !== EmployeeRole.TESTER) {
        <div class="flex flex-col items-center justify-center">
          <label class="mb-2 block text-sm font-medium">Archived</label>
          <input
            type="checkbox"
            [(ngModel)]="employee.isArchived"
            class="h-5 w-9 appearance-none rounded-full bg-gray-300 shadow-inner transition-all duration-300 before:ml-0.5 before:inline-block before:h-4 before:w-4 before:rounded-full before:bg-sunflowerGold checked:bg-yellow-200 checked:before:translate-x-full focus:outline-none"
            data-cy="employee-archived"
          />
        </div>
      }

      <div class="flex flex-col items-center justify-center">
        <label class="mb-2 block text-sm font-medium">Overtime</label>
        <input
          type="checkbox"
          [(ngModel)]="employee.countOvertime"
          class="h-5 w-9 appearance-none rounded-full bg-gray-300 shadow-inner transition-all duration-300 before:ml-0.5 before:inline-block before:h-4 before:w-4 before:rounded-full before:bg-sunflowerGold checked:bg-yellow-200 checked:before:translate-x-full focus:outline-none"
          data-cy="employee-overtime"
        />
      </div>
    </div>
    <div class="mt-8 flex w-full justify-between">
      <button
        (click)="onClose()"
        class="mb-2 flex items-center border bg-white px-5 py-2 text-sm font-medium tracking-wider text-slateGray shadow-sm hover:bg-gray-100 hover:shadow-lg md:mb-0"
        data-cy="close-employee-dialog"
      >
        Close
      </button>
      <button
        (click)="onSubmit()"
        class="mb-2 flex items-center justify-center border border-black bg-almostBlack px-5 py-2.5 text-center text-sm font-medium tracking-wider text-sunflowerGold shadow-sm hover:bg-black hover:shadow-lg focus:outline-none focus:ring-4 sm:w-auto md:mb-0"
        data-cy="save-employee-dialog"
      >
        Save
      </button>
    </div>
  </div>
</div>
