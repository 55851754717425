<app-working-time
  (fetchEmployees)="getEmployees()"
  (employeeUpdate)="updateEmployee($event)"
  [employees]="employees"
></app-working-time>
<div class="flex w-full justify-center">
  <div class="mb-5 flex w-full max-w-7xl flex-col gap-4 bg-lightGrayish p-5 lg:gap-5">
    <div class="flex items-center justify-between">
      <app-text-content-box headline="Trainings of employees" headerIcon="🗓️" class="lg:pb-2"></app-text-content-box>
      <div class="flex gap-4">
        <label class="text-sm font-medium text-black">Past requests</label>
        <input
          type="checkbox"
          class="flex h-5 w-9 min-w-[2rem] appearance-none items-center rounded-full bg-gray-300 shadow-inner transition-all duration-300 before:ml-0.5 before:inline-block before:h-4 before:w-4 before:rounded-full before:bg-sunflowerGold checked:bg-yellow-200 checked:before:translate-x-full focus:outline-none"
          data-cy="employee-trainings-toggle"
          (change)="toggleOpenTrainings()"
        />
      </div>
    </div>
    @if (!trainings || !trainings.length) {
      <div>
        <span>There are currently no requests for trainings</span>
      </div>
    }
    @if (trainings && trainings.length) {
      <div class="max-h-80 overflow-y-auto">
        <div class="sticky top-0 flex w-full text-white">
          <div class="w-60 min-w-[10rem] border-r bg-black p-2">Employee</div>
          <div class="min-w-[10rem] flex-1 border-r bg-black p-2">Date</div>
          <div class="min-w-[10rem] flex-1 border-r bg-black p-2">Time worked</div>
          <div class="w-60 min-w-[10rem] border-r bg-black p-2">Description</div>
          <div class="min-w-[10rem] border-r bg-black p-2">Method</div>
          <div class="w-60 min-w-[10rem] border-r bg-black p-2">Reason</div>
          @if (showIsRequested) {
            <div class="flex min-w-[10rem] flex-1 justify-center bg-black p-2">Actions</div>
          }
          @if (!showIsRequested) {
            <div class="flex min-w-[10rem] flex-1 justify-center bg-black p-2">Status</div>
          }
        </div>
        <div>
          @for (training of trainings; track training) {
            <div class="flex w-full">
              <div
                class="w-60 min-w-[10rem] overflow-hidden text-ellipsis whitespace-nowrap border-b border-l border-r p-2"
                [title]="getTrainingEmployee(training)"
              >
                {{ getTrainingEmployee(training) }}
              </div>
              <div class="min-w-[10rem] flex-1 border-b border-r p-2">
                {{ getTrainingDate(training) | date: 'dd.MM.yyyy' }}
              </div>
              <div class="min-w-[10rem] flex-1 border-b border-r p-2">{{ getTrainingDuration(training.duration) }}</div>
              <div
                class="w-60 min-w-[10rem] overflow-hidden text-ellipsis whitespace-nowrap border-b border-r p-2"
                data-cy="training-description"
                [tooltip]="training.description"
                [showDelay]="250"
              >
                {{ training.description }}
              </div>
              <div class="min-w-[10rem] flex-1 border-b border-r p-2" data-cy="training-method">
                {{ training.method }}
              </div>
              <div
                class="w-60 min-w-[10rem] overflow-hidden text-ellipsis whitespace-nowrap border-b border-r p-2"
                data-cy="training-reason"
                [tooltip]="training.reason"
                [showDelay]="250"
              >
                {{ training.reason }}
              </div>
              @if (showIsRequested) {
                <div class="flex min-w-[10rem] flex-1 justify-center gap-8 border-b border-r p-2">
                  <img
                    tooltip="Accept training"
                    [showDelay]="250"
                    (click)="approveTraining(training)"
                    data-cy="training-approve-button"
                    class="h-5 cursor-pointer"
                    src="../../../assets/img/icons/check.png"
                    alt="accept"
                  />
                  <img
                    tooltip="Reject training"
                    [showDelay]="250"
                    (click)="rejectTraining(training)"
                    data-cy="training-reject-button"
                    class="h-5 cursor-pointer"
                    src="../../../assets/img/icons/x-mark.png"
                    alt="x-mark"
                  />
                </div>
              }

              @if (!showIsRequested) {
                <div class="flex min-w-[10rem] flex-1 justify-center gap-8 border-b border-r p-2">
                  @if (training.state === ETrainingState.APPROVED) {
                    <span class="text-green-500">Approved</span>
                  }
                  @if (training.state === ETrainingState.REJECTED) {
                    <span class="text-red-500">Rejected</span>
                  }
                </div>
              }
            </div>
          }
        </div>
      </div>
    }
  </div>
</div>

<div class="flex w-full flex-col items-center justify-center">
  <div class="w-full max-w-7xl">
    <div class="mb-5 flex flex-col gap-4 bg-lightGrayish p-5 lg:gap-5">
      <app-text-content-box [headline]="'Employees'" [headerIcon]="'👨‍👨‍👦'" class="lg:pb-2"></app-text-content-box>
      <div class="flex">
        <div class="flex w-2/5 items-center gap-4 pr-4">
          <div class="relative flex items-center">
            <input
              type="search"
              class="remove-arrow block h-12 border border-[#D1D1D1] p-2.5 pr-6 text-sm placeholder-veryTransparentBlack placeholder:font-semibold focus:border-sunflowerGold focus:ring-sunflowerGold lg:w-44 min_w_1640:w-[216px]"
              placeholder="Search employee"
              [(ngModel)]="searchText"
              (input)="searchEmployees()"
            />
            <img class="absolute right-4 h-4" src="../../../assets/img/icons/search.png" alt="search" />
          </div>

          <label class="text-sm font-medium text-black">Archived</label>
          <input
            type="checkbox"
            [(ngModel)]="showAllEmployees"
            [ngModelOptions]="{ standalone: true }"
            class="flex h-5 w-9 min-w-[2rem] appearance-none items-center rounded-full bg-gray-300 shadow-inner transition-all duration-300 before:ml-0.5 before:inline-block before:h-4 before:w-4 before:rounded-full before:bg-sunflowerGold checked:bg-yellow-200 checked:before:translate-x-full focus:outline-none"
            data-cy="employee-archived-filter"
          />
        </div>
      </div>
      <div class="mt-4 h-auto max-h-[530px] overflow-y-auto">
        <table data-cy="manage-employee-table">
          <thead class="sticky top-0 z-10 bg-black text-center text-white">
            <tr>
              @for (condition of sortConditions; track sortConditions) {
                <th class="px-4 py-2 text-left font-semibold">
                  {{ condition.label }}
                  <fa-icon
                    [id]="condition.id"
                    (click)="sortEmployeesByCondition(condition.id)"
                    class="ml-2 cursor-pointer bg-black text-white"
                    [ngClass]="{ highlightArrow: activeSortCondition === condition.id }"
                  >
                  </fa-icon>
                </th>
              }

              <th class="px-4 py-2 font-semibold">Actions</th>
            </tr>
          </thead>
          <tbody>
            @for (employee of filteredEmployees; track employees) {
              <tr
                [ngClass]="{ 'bg-red-50': employee.isArchived }"
                class="mb-2 table-row flex-col flex-wrap odd:bg-white sm:mb-0"
              >
                @if (employee.isArchived === showAllEmployees) {
                  <ng-container>
                    <td class="border px-4 py-2">{{ employee?.firstname }} {{ employee?.lastname }}</td>
                    <td class="truncate border px-4 py-2">
                      {{ helperService.formatMinutes(employee?.overtimeBalance) }}
                    </td>
                    <td class="truncate border px-4 py-2">
                      {{ employee?.vacationDays }}
                    </td>
                    <td class="truncate border px-4 py-2">
                      {{ employee?.coinBalance ?? 0 }}
                    </td>
                    <td class="relative w-[150px] border">
                      <div class="flex w-full justify-center">
                        <div
                          tooltip="Edit employee"
                          [showDelay]="250"
                          (click)="openEmployeeDialog('edit', employee)"
                          class="flex w-1/2 cursor-pointer items-center justify-center gap-2"
                          data-cy="edit-employee-button"
                        >
                          <img class="h-4" src="assets/img/icons/edit.png" alt="edit" />
                        </div>

                        <div
                          tooltip="Show overtime balance"
                          [showDelay]="250"
                          (click)="openHistoryDialog(DialogType.OVERTIME, employee)"
                          class="flex w-1/2 cursor-pointer items-center justify-center gap-2"
                          data-cy="overtime-history-button"
                        >
                          <img class="h-4" src="assets/img/vacation.png" alt="vacation" />
                        </div>

                        <div
                          tooltip="Show coin balance"
                          [showDelay]="250"
                          (click)="openHistoryDialog(DialogType.COINBALANCE, employee)"
                          class="flex w-1/2 cursor-pointer items-center justify-center gap-2"
                          data-cy="coin-history-button"
                        >
                          <img class="h-4" src="assets/img/coin.png" alt="overtime" />
                        </div>

                        <div class="flex w-1/2 cursor-pointer items-center justify-center gap-2">
                          @if (!employee.isArchived) {
                            <img
                              tooltip="Archive employee"
                              [showDelay]="250"
                              (click)="openConfirmDialog('delete', employee)"
                              class="h-4"
                              data-cy="delete-user-button"
                              src="assets/img/icons/delete.png"
                            />
                          }

                          @if (employee.isArchived) {
                            <img
                              tooltip="Reactivate employee"
                              [showDelay]="250"
                              (click)="openConfirmDialog('reactivate', employee)"
                              class="imgHeight35"
                              data-cy="unarchive-employee-button"
                              src="assets/img/icons/restore.png"
                            />
                          }
                        </div>
                      </div></td
                  ></ng-container>
                }
              </tr>
            }
          </tbody>
        </table>

        @if (manageEmployeeDialog) {
          <app-manage-employee-dialog
            (closeDialog)="closeEmployeeDialog()"
            (employeeData)="manageEmployeeDialogHandler($event)"
            [data]="dataToConfirm"
          ></app-manage-employee-dialog>
        }

        @if (isConfirmDialogOpen) {
          <app-confirm-dialog
            (saveChanges)="confirmedChangesHandler($event)"
            (closeDialog)="closeConfirmDialog()"
            [data]="dataToConfirm"
          ></app-confirm-dialog>
        }
      </div>

      @if (environment.showTester) {
        <app-text-content-box
          [headline]="'Tester-Accounts'"
          [headerIcon]="'👨🏻‍🔧'"
          class="mt-4 lg:pb-2"
        ></app-text-content-box>
        <table class="" data-cy="manage-tester-table">
          <thead class="sticky top-0 z-10 bg-black text-center text-white">
            <tr>
              @for (condition of sortConditionsTester; track sortConditionsTester) {
                <th class="px-4 py-2 text-left font-semibold">
                  {{ condition.label }}
                  <fa-icon
                    [id]="condition.id"
                    (click)="sortEmployeesByCondition(condition.id)"
                    class="ml-2 cursor-pointer bg-black text-white"
                    [ngClass]="{ highlightArrow: activeSortCondition === condition.id }"
                  >
                  </fa-icon>
                </th>
              }

              <th class="px-4 py-2 font-semibold">Actions</th>
            </tr>
          </thead>
          @if (!tester || tester.length === 0) {
            <div class="ml-2 mt-4">
              <span>There is no tester</span>
            </div>
          }
          <tbody>
            @for (employee of tester; track employees) {
              <tr
                [ngClass]="{ 'bg-red-50': employee.isArchived }"
                class="mb-2 table-row flex-col flex-wrap odd:bg-white sm:mb-0"
              >
                <ng-container>
                  <td class="border px-4 py-2">{{ employee?.firstname }} {{ employee?.lastname }}</td>
                  <td class="truncate border px-4 py-2">
                    {{ helperService.formatMinutes(employee?.overtimeBalance) }}
                  </td>
                  <td class="truncate border px-4 py-2">
                    {{ employee?.vacationDays }}
                  </td>
                  <td class="truncate border px-4 py-2">
                    {{ employee?.coinBalance ?? 0 }}
                  </td>
                  <td class="relative w-[150px] border">
                    <div class="flex w-full justify-center">
                      <div
                        tooltip="Edit Tester-Account"
                        [showDelay]="250"
                        (click)="openEmployeeDialog('edit', employee)"
                        class="flex w-1/2 cursor-pointer items-center justify-center gap-2"
                        data-cy="edit-tester-button"
                      >
                        <img class="h-4" src="assets/img/icons/edit.png" alt="edit" />
                      </div>

                      <div
                        tooltip="Show overtime balance"
                        [showDelay]="250"
                        (click)="openHistoryDialog(DialogType.OVERTIME, employee)"
                        class="flex w-1/2 cursor-pointer items-center justify-center gap-2"
                        data-cy="overtime-history-tester-button"
                      >
                        <img class="h-4" src="assets/img/vacation.png" alt="vacation" />
                      </div>

                      <div
                        tooltip="Show coin balance"
                        [showDelay]="250"
                        (click)="openHistoryDialog(DialogType.COINBALANCE, employee)"
                        class="flex w-1/2 cursor-pointer items-center justify-center gap-2"
                        data-cy="coin-history-tester-button"
                      >
                        <img class="h-4" src="assets/img/coin.png" alt="overtime" />
                      </div>

                      <div
                        tooltip="Copy password"
                        [showDelay]="250"
                        (click)="copyTesterPassword()"
                        class="flex w-1/2 cursor-pointer items-center justify-center gap-2"
                        data-cy="coin-history-tester-button"
                      >
                        <img class="h-4" src="assets/img/icons/save.png" alt="overtime" />
                      </div>

                      <div class="flex w-1/2 cursor-pointer items-center justify-center gap-2">
                        @if (!employee.isArchived) {
                          <img
                            tooltip="Reactivate employee"
                            [showDelay]="250"
                            (click)="openConfirmDialog('delete', employee)"
                            class="h-4"
                            data-cy="delete-tester-button"
                            src="assets/img/icons/delete.png"
                          />
                        }
                      </div>
                    </div></td
                ></ng-container>
              </tr>
            }
          </tbody>
        </table>
      }

      <div class="flex w-full justify-end max-lg:mb-4 max-lg:mt-2 max-lg:justify-start">
        <button
          (click)="openEmployeeDialog('add')"
          type="button"
          class="flex h-11 w-full items-center justify-center border-black bg-almostBlack p-2 font-semibold text-sunflowerGold max-lg:gap-4 lg:w-40 lg:gap-4"
          data-cy="add-employee-button"
        >
          <p>Add</p>
          <fa-icon class="text-lg" [icon]="addUserIcon"></fa-icon>
        </button>
      </div>
    </div>

    <app-company-goals>
      <div class="flex w-full justify-between gap-4 pb-4 pt-4 lg:pb-3 lg:pt-3">
        <input
          [(ngModel)]="goalText"
          placeholder="Add a new goal"
          type="text"
          class="block h-12 w-full border border-[#D1D1D1] p-2.5 pr-10 text-sm placeholder-veryTransparentBlack placeholder:font-semibold focus:border-sunflowerGold focus:ring-sunflowerGold"
          data-cy="goal-text-input"
        />
        <button
          class="flex h-12 w-40 items-center justify-center gap-2 border-black bg-almostBlack"
          data-cy="goal-save-button"
          (click)="onCreateCompanyGoal(goalText)"
        >
          <img class="h-[18px] cursor-pointer" src="assets/img/icons/save.png" alt="save" />
        </button>
      </div>
      <app-text-content-box [headline]="'Current Goals'" [headerIcon]="'⬆️'"></app-text-content-box>
    </app-company-goals>

    <div class="mb-5 flex flex-col gap-4 bg-lightGrayish p-5 lg:gap-5" data-cy="coin-transfer-section">
      <app-text-content-box [headline]="'42 Coin Transfer'" [headerIcon]="'💰'" class="lg:pb-2"></app-text-content-box>

      <div class="flex flex-col gap-4 md:flex-row">
        <div
          (focusout)="showEmployeesDropdown = false"
          tabindex="0"
          class="z-20 h-12 w-full cursor-pointer font-semibold max-smC:w-[6.5rem] max-smC:text-sm md:w-2/5"
        >
          <app-dropdown
            data-cy="admin-employee-dropdown"
            #employeeDropdown
            [elements]="getNotArchivedEmployees()"
            placeholder="Select employee"
            [isEmployeeDropdown]="true"
            (selectElement)="onSelectEmployee($event)"
          ></app-dropdown>
        </div>
        <div class="hidden border-r-2 md:block"></div>

        <div class="w-full md:w-2/5">
          <input
            class="remove-arrow block h-12 w-full border border-gray-300 p-2.5 placeholder-veryTransparentBlack placeholder:font-semibold"
            class="remove-arrow block h-12 w-full border border-gray-300 p-2.5 placeholder-veryTransparentBlack placeholder:font-semibold"
            data-cy="ct-amount-input"
            type="number"
            pattern="[0-9]"
            placeholder="Value"
            [(ngModel)]="coinTransfer.amount"
          />
        </div>
        <div class="w-full">
          <input
            [(ngModel)]="coinTransfer.reason"
            class="block h-12 w-full border border-gray-300 p-2.5 placeholder-veryTransparentBlack placeholder:font-semibold"
            data-cy="ct-reason-input"
            type="text"
            placeholder="Reason"
          />
        </div>
        <div class="flex w-full justify-end md:w-fit">
          <button
            (click)="onTransferCoin()"
            class="flex h-12 w-28 items-center justify-center border-black bg-almostBlack"
            data-cy="ct-save-button"
          >
            <img class="h-[18px] cursor-pointer" src="assets/img/icons/save.png" alt="save" />
          </button>
        </div>
      </div>
    </div>

    <div class="mb-5 flex flex-col gap-4 bg-lightGrayish p-5 lg:gap-5">
      <div class="relative flex items-center">
        <app-text-content-box [headline]="'42 Shop Purchasing'" [headerIcon]="'🛍️'"></app-text-content-box>
        <div class="absolute right-0 flex gap-2">
          <label class="text-sm font-medium text-black">Done</label>
          <input
            type="checkbox"
            (change)="toggleTransactions((showRequestedTransactions = !showRequestedTransactions))"
            class="flex h-5 w-9 min-w-[2rem] appearance-none items-center rounded-full bg-gray-300 shadow-inner transition-all duration-300 before:ml-0.5 before:inline-block before:h-4 before:w-4 before:rounded-full before:bg-sunflowerGold checked:bg-yellow-200 checked:before:translate-x-full focus:outline-none"
          />
        </div>
      </div>

      <div class="h-auto max-h-[370px] overflow-y-auto">
        <table>
          <thead class="sticky top-0 z-10 bg-black text-center text-white">
            <tr>
              @for (condition of purchaseEntryConditions; track condition) {
                <th class="px-4 py-2 text-left font-semibold">
                  {{ condition.label }}
                  <br />
                </th>
              }

              <th class="font-semibold">Actions</th>
            </tr>
          </thead>
          <tbody>
            @for (request of productRequests; track request) {
              <tr class="table-row flex-col flex-wrap">
                <ng-container>
                  <td class="border px-4 py-2">{{ request.transactionTime | date: 'dd.MM.yyyy' }}</td>
                  <td class="truncate border px-4 py-2">
                    {{ request.employee.firstname + ' ' + request.employee.lastname }}
                  </td>
                  <td class="border px-4 py-2">
                    {{ request?.product?.title }} {{ request.size ? ' - ' + request.size : '' }}
                  </td>
                  <td class="border px-4 py-2">{{ request.product.price }}</td>
                  <td class="truncate border px-4 py-2">{{ request.employee.address }}</td>
                  @if (request.state === eState.REQUESTED) {
                    <td>
                      <div class="flex justify-around">
                        <img
                          tooltip="Accept article"
                          [showDelay]="250"
                          (click)="managePurchasing('acceptArticle', request)"
                          class="h-5 cursor-pointer"
                          class="h-5 cursor-pointer"
                          src="../../../assets/img/icons/check.png"
                          alt="accept"
                        />
                        <img
                          tooltip="Remove article"
                          [showDelay]="250"
                          (click)="managePurchasing('removeArticle', request)"
                          class="h-5 cursor-pointer"
                          class="h-5 cursor-pointer"
                          src="../../../assets/img/icons/x-mark.png"
                          alt="x-mark"
                        />
                      </div>
                    </td>
                  }

                  @if (request.state === eState.CANCELED) {
                    <td class="text-center font-semibold text-[#E6AE48]">Cancelled</td>
                  }

                  @if (request.state === eState.APPROVED) {
                    <td class="text-center font-semibold text-[#E6AE48]">Approved</td>
                  }
                </ng-container>
              </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<app-admin-sick-leaves [employees]="getNotArchivedEmployees()"> </app-admin-sick-leaves>
@if (showTrainingsConfirmDialog) {
  <app-confirmation-dialog
    (acceptChanges)="onConfirmTraining($event)"
    (closeDialog)="closeConfirmDialog()"
    [data]="confirmTrainingData"
  ></app-confirmation-dialog>
}
@if (showHistoryDialog) {
  <app-history-dialog
    (closeDialog)="showHistoryDialog = false"
    [data]="historyDialogData"
    [employees]="employees"
  ></app-history-dialog>
}
