<app-sorting-segment (sortOptionSelected)="sortOptionSelected($event)"></app-sorting-segment>

@for (idea of helperService.ideas$ | async; track idea; let i = $index) {
  <div class="flex flex-col">
    @if (!idea.isSolved) {
      <div class="center mb-1 flex w-full flex-col items-center pt-5 first:pt-2">
        @if (showRemoveButton()) {
          <button (click)="createRemoveModal(idea, 'box')" class="z-10 -mb-5 flex w-10">
            <img src="../../../assets/img/icons/solve.png" alt="solve" />
          </button>
        }
        <div
          class="relative flex w-full flex-col items-center border-2 border-b-0 border-sunflowerGold bg-paleOrange p-3 max-lg:pt-5 max-lg:text-xs lg:p-5"
          data-cy="productidea-item"
        >
          {{ idea.text }}
          <div class="relative mt-5 flex w-full flex-col items-center justify-normal gap-1 max-sm:flex-col-reverse">
            <div class="flex items-center">
              <button
                (click)="toggleComments(i)"
                class="absolute left-0 flex items-center gap-2 lg:left-2"
                data-cy="productidea-comments-symbol"
              >
                <fa-icon class="lg:scale-125" [icon]="commentIcon"></fa-icon>
                <span class="text-xs lg:text-base"> {{ idea.comments.length }}</span>
              </button>

              <div class="flex items-center gap-2">
                <p class="font-bold lg:text-2xl" data-cy="productidea-vote-count">{{ idea.voteCount }}</p>
                <button (click)="upvoteProductIdea(idea)" data-cy="pi-arrow-up">
                  <img class="h-7" src="../../../assets/img/icons/arrow-up.png" alt="Arrow up" />
                </button>
              </div>
              @if (idea.fileUrl) {
                <button>
                  <a [href]="idea.fileUrl" target="_blank">
                    <fa-icon
                      tooltip="Open product idea"
                      [showDelay]="250"
                      class="absolute bottom-2 right-0 flex cursor-pointer items-center lg:right-2 lg:scale-125"
                      data-cy="productidea-link"
                      [icon]="faLink"
                    ></fa-icon>
                  </a>
                </button>
              }
            </div>
          </div>
        </div>

        <div
          [class.active]="showComments[i]"
          class="comment-section w-full border-2 border-sunflowerGold bg-paleOrange"
          data-cy="productidea-comments-list"
          [ngClass]="{
            'border-t-0': !showComments[i],
            'comment-section-mobile': helperService.isPhoneSize$ | async,
          }"
        >
          @for (comment of idea.comments; track comment) {
            <div
              class="mx-4 flex justify-between border-b border-[white] bg-paleOrange py-2 text-xs lg:text-base"
              data-cy="productidea-single-comment"
              [ngClass]="{
                'border-b-0': comment === idea.comments[idea.comments.length - 1],
              }"
            >
              <span class="w-11/12 break-words">{{ comment.comment }}</span>
              @if (comment.commentedBy === authService.getCurrentEmployee()._id) {
                <button
                  class="flex pt-1"
                  (click)="createRemoveModal(idea, 'comment', comment._id)"
                  data-cy="productidea-delete-comment-button"
                >
                  <img class="h-3 lg:h-4" src="../../../assets/img/icons/delete.png" alt="delete" />
                </button>
              }
            </div>
          }
          <div class="relative flex items-center bg-paleOrange">
            <textarea
              class="no-focus h-10 w-full resize-none p-2 text-xs lg:text-base"
              data-cy="productidea-comment-textarea"
              (keydown)="enforcePrefix(i)"
              [(ngModel)]="commentPrefix[i]"
            >
            </textarea>
            <button
              class="flex h-10 w-12 items-center justify-center bg-white p-1"
              (click)="onCreateComment(idea._id, i)"
              data-cy="productidea-send-comment-button"
            >
              <img class="h-4" src="../../../assets/img/icons/send.png" alt="send" />
            </button>
          </div>
        </div>
      </div>
    }
    @if (showConfirmDialog) {
      <app-confirm-dialog
        (closeDialog)="showConfirmDialog = false"
        (saveChanges)="
          currentOwnCommentId && currentIdeaId
            ? deleteOwnComment(currentIdeaId, currentOwnCommentId)
            : resolveProductIdea(currentProductIdea)
        "
        [data]="modalData"
      ></app-confirm-dialog>
    }
  </div>
}
