import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IProduct } from '../models/product.model';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private http: HttpClient) {}

  public addNewProduct(product: IProduct): Observable<any> {
    const formData: FormData = new FormData();

    const img = product.image as File;
    formData.append('image', img, img.name);
    formData.append('title', product.title);
    formData.append('price', product.price.toString());
    formData.append('description', product.description);
    formData.append('type', product.type);

    return this.http.post(environment.baseUrl + '/products', formData);
  }

  public getAllProducts(): Observable<any> {
    return this.http.get(environment.baseUrl + '/products/all');
  }
  public getProductsImage(imageId: string): Observable<any> {
    return this.http.get(environment.baseUrl + `/products/image/${imageId}`, { responseType: 'blob' });
  }

  public updateProduct(product: IProduct): Observable<any> {
    const formData: FormData = new FormData();

    const img = product.image as File;
    if (img) formData.append('image', img, img.name);
    formData.append('_id', product._id);
    formData.append('title', product.title);
    formData.append('price', product.price.toString());
    formData.append('description', product.description);
    formData.append('type', product.type);
    return this.http.put(environment.baseUrl + '/products/' + product._id, formData);
  }
  public deleteProduct(productId: string): Observable<any> {
    return this.http.delete(environment.baseUrl + '/products/' + productId);
  }
}
