<div class="flex w-full flex-col items-center justify-center gap-5">
  <div class="flex w-full max-w-7xl flex-col gap-5">
    @if (this.authService.getCurrentEmployee().isAdmin) {
      <div class="flex w-full flex-col gap-4 bg-lightGrayish p-5 lg:gap-7" data-cy="rev-add-target-container">
        <app-text-content-box [headline]="'Insert new Target'" [headerIcon]="'📈'"></app-text-content-box>
        <div class="flex flex-col gap-5 lg:flex-row" data-cy="rev-add-target-section">
          <app-dropdown
            #monthDropdown
            [elements]="monthNames"
            placeholder="Month"
            (selectElement)="onSelectMonth($event)"
          ></app-dropdown>

          <div class="hidden border-l border-[#E5E5E5] lg:flex"></div>
          <app-dropdown
            #revenueDropdown
            [elements]="revenues"
            [isRevenueDropdown]="true"
            placeholder="Revenue"
            (selectElement)="onSelectRevenue($event)"
          ></app-dropdown>

          <div class="relative flex w-full items-center lg:w-auto">
            <input
              class="block h-12 w-full border border-gray-300 p-2.5 pr-7 text-sm placeholder-veryTransparentBlack placeholder:font-semibold lg:w-auto"
              data-cy="rev-value-input"
              placeholder="Value"
              name="valueOfRevenue"
              [(ngModel)]="revenueValue"
              min="0"
              type="number"
            />
            <p class="absolute right-3 text-veryTransparentBlack">€</p>
          </div>

          <button
            (click)="createNewRevenueTarget()"
            class="flex h-12 w-full items-center justify-center gap-2 border-black bg-almostBlack lg:w-20"
            data-cy="rev-save-btn"
          >
            <img class="h-[1.1rem] cursor-pointer" src="assets/img/icons/save.png" alt="save" />
          </button>
        </div>
      </div>
    }
    <app-revenue></app-revenue>
    <div class="flex w-full flex-col gap-5 bg-lightGrayish" *mcRerender="reRenderCharts">
      <div class="flex flex-col items-center gap-4 lg:gap-7">
        <div class="flex w-full gap-1 p-5 pb-0">
          <app-text-content-box [headline]="'Revenue Targets (Quarter)'" [headerIcon]="'📈'"></app-text-content-box>
        </div>
        <div class="mx-auto w-full overflow-hidden p-8 pt-0">
          <canvas id="quarterBarChart"></canvas>
          <div class="flex justify-between pl-24 text-[0.25rem] font-medium sm:-mr-4 sm:pl-[4.625rem] sm:text-base">
            @for (value of valuesQuarterHTML; track value) {
              <div class="flex flex-col items-center">
                <div class="flex w-[53%] justify-center">
                  <p>{{ value.real }}</p>
                </div>
                <p>{{ value.name ? value.name : 'Month' }}</p>
              </div>
            }
          </div>
        </div>
      </div>
    </div>

    <div class="relative mb-5 flex w-full flex-col gap-5 bg-lightGrayish" *mcRerender="reRenderCharts">
      <div class="flex flex-col items-center gap-4 lg:gap-7">
        <div class="flex w-full gap-1 p-5 pb-0">
          <app-text-content-box [headline]="'Revenue Targets (Month)'" [headerIcon]="'📈'"></app-text-content-box>
        </div>
        <div class="mx-auto w-full overflow-hidden p-8 pt-0">
          <canvas id="yearBarChart"> </canvas>
          <div
            class="-mt-4 flex justify-between pb-2 pl-[96px] font-medium max-lg:text-[0.5rem] sm:-mr-[24px] sm:pl-[40px] xl:-mr-[34px] xl:pl-[26px]"
          >
            @if (!isPhoneSize) {
              <div class="flex flex-col justify-end">
                <p class="items-center">REAL</p>
              </div>
            }
            @for (month of valuesYearHTML; track month; let i = $index) {
              <div class="flex w-full flex-col items-center justify-around" data-cy="canva-month">
                @if (!isPhoneSize) {
                  <div>{{ month.name.substring(0, 3) }}</div>
                }
                @if (isPhoneSize) {
                  <div>{{ month.name.substring(0, 1) }}</div>
                }

                @if (!isPhoneSize) {
                  <div
                    class="flex h-8 w-full items-end justify-center"
                    data-cy="canva-real-value"
                    [ngClass]="{ year_border: (i + 1) % 3 === 0 && i !== valuesYearHTML.length - 1 }"
                  >
                    {{ month.real }}
                  </div>
                }
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
