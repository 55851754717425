import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { LeaveTimeType } from 'src/app/enum/leave.enum';
import { IConfirmData } from 'src/app/models/confirm-data';
import { DateOfMonth } from 'src/app/models/daysOfMonths.model';
import { IEmployee } from 'src/app/models/employee.model';
import { ILeaveDialog } from 'src/app/models/leave-dialog.model';
import { ILeave, Leave, LeaveEvent } from 'src/app/models/leave.model';
import { EmployeesResponse, LeaveResponse, LeavesResponse } from 'src/app/models/response.model';
import { AuthService } from 'src/app/services/auth.service';
import { EmployeeService } from 'src/app/services/employee.service';
import { HelperService } from 'src/app/services/helper.service';
import { LeaveService } from 'src/app/services/leave.service';

@Component({
  selector: 'app-employee',
  templateUrl: './holiday-planning.component.html',
  styleUrls: ['./holiday-planning.component.scss'],
})
export class HolidayPlanning {
  private subscription = new Subscription();
  public isLoading = true;

  public datesOfCurrentMonth: DateOfMonth[] = [];
  private allLeaveEvents: LeaveEvent[][] = [];
  public employees: IEmployee[];
  public dataToConfirm = {} as IConfirmData;

  public myLeaves: ILeave[];
  public allLeaves: ILeave[];
  private leaves: ILeave[];

  public isConfirmDialogOpen = false;

  public leaveData = {} as ILeave;
  public isLeaveDialogOpen = false;
  public leaveDialog = {} as ILeaveDialog;

  public month: number;
  public year: number;
  public date: number | Date;

  monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  constructor(
    private authService: AuthService,
    private leaveService: LeaveService,
    private employeeService: EmployeeService,
    private helperService: HelperService,
    private toastr: ToastrService,
  ) {
    this.getAllEmployees();
    this.getCurrentDate();
  }

  public getOvertimeBalance(): string {
    const overtime = this.authService.getCurrentEmployee().overtimeBalance;
    return this.helperService.formatMinutes(overtime);
  }

  public getCurrentEmployee(): IEmployee {
    return this.authService.getCurrentEmployee();
  }

  // Gets current Date and all days of current Month
  private getCurrentDate(): void {
    const date = new Date();
    this.month = date.getMonth();
    this.year = date.getFullYear();
    this.date = date.setDate(1);

    this.getDaysInMonth(date);
  }

  private getAllEmployees(): void {
    this.subscription.add(
      this.employeeService.getAllEmployees().subscribe({
        next: (res: EmployeesResponse) => {
          this.employees = res.DATA.filter((employees) => !employees.isArchived);
          this.getCurrentLeaves();
        },
        error: () => {
          this.toastr.error('Error loading data');
        },
      }),
    );
  }

  proofAndTransferLeave(leave: ILeave, action: 'delete' | 'edit' | 'add'): void {
    if (!leave.startDate || !leave.endDate) {
      this.toastr.error('Start date or end date is invalid');
      return;
    }
    this.transferLeave(leave, action);
  }

  transferLeave(leave: ILeave, action: 'delete' | 'edit' | 'add'): void {
    // let { vacationDaysRequested, employeesAvailableVacationDays, employee } = this.setNewLeaveDates(leave);
    // if (vacationDaysRequested <= employeesAvailableVacationDays) {
    let employee = this.employees.find((employee) => employee._id === leave.employeeId); // only for testing
    this.forwardLeaveAction(leave, action, employee);
    // } else {
    // this.toastr.error('Not enough vacation days available.');
    // }
  }

  // setNewLeaveDates(leave: ILeave): { vacationDaysRequested: number; employeesAvailableVacationDays: number; employee: IEmployee } {
  //   let vacationDaysRequested = this.calculateWorkDays(leave);
  //   let employee = this.employees.find((employee) => employee._id === leave.employeeId); // ?Object reference dont work here...
  //   let employeesAvailableVacationDays = employee.vacationDays;
  //   employee.vacationDays = employeesAvailableVacationDays - vacationDaysRequested;

  //   return { vacationDaysRequested, employeesAvailableVacationDays, employee };
  // }

  // calculateWorkDays(leave: ILeave): number {
  //   let workDays = 0;
  //   let currentDate = new Date(leave.startDate);
  //   while (currentDate <= leave.endDate) {
  //     const dayOfWeek = currentDate.getDay();
  //     if (dayOfWeek !== 0 && dayOfWeek !== 6) {
  //       workDays += 1;
  //     }
  //     currentDate.setDate(currentDate.getDate() + 1);
  //   }
  //   return workDays;
  // }

  forwardLeaveAction(leave: ILeave, action: 'delete' | 'edit' | 'add', employee: IEmployee): void {
    if (action === 'edit') {
      this.editLeave(leave, employee);
    }
    if (action === 'add') {
      leave.type = LeaveTimeType.OVERTIME;
      this.addNewLeave(leave, employee);
    }
    if (action === 'delete') {
      this.deleteLeave(leave, employee);
    }
    // this.updateEmployee(employee);
    this.getAllEmployees();
  }

  updateEmployee(employee: IEmployee): void {
    if (employee._id === this.authService.getCurrentEmployee()._id) {
      this.authService.setCurrentEmployee(employee);
    }
  }

  addNewLeave(leave: ILeave, employee: IEmployee): void {
    leave.startDate = new Date(leave.startDate).toISOString();
    leave.endDate = new Date(leave.endDate).toISOString();
    leave.employeeName = employee.firstname + ' ' + employee.lastname;
    this.subscription.add(
      this.leaveService.addLeave(leave).subscribe({
        next: (res: LeaveResponse) => {
          this.toastr.success('Leave was successfully added');
          this.pushLeaveAfterAdding(res.DATA);
          // this.backendService.editEmployee(employee);
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  /**
   * Pushes new leave to all arrays for displaying it without backend update
   * @param {Leave} leave - leave that was added
   */
  private pushLeaveAfterAdding(leave: ILeave): void {
    this.allLeaves.push(leave);
    this.leaves.push(leave);
    if (leave.employeeId === this.authService.getCurrentEmployee()._id) {
      this.myLeaves.push(leave);
    }
    this.sortMyLeavesByDate();
    this.closeLeaveDialog();
    this.createDatesForLeaves();

    if (new Date(leave.startDate).getMonth() === this.month) {
      this.formatLeavesForCalendar();
    }
  }

  editLeave(leave: ILeave, employee: IEmployee): void {
    this.subscription.add(
      this.employeeService.editLeave(leave).subscribe({
        next: () => {
          this.toastr.success('Leave was successfully edited');
          this.pushLeavesAfterEditing(leave);
          this.employeeService.editEmployee(employee);
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  /**
   * Pushes leave to all arrays to instantly display it.
   * @param {Leave} leave - leave that was edited
   */
  private pushLeavesAfterEditing(leave: ILeave): void {
    this.leaves[leave._id] = leave;
    if (leave.employeeId === this.authService.getCurrentEmployee()._id) {
      this.myLeaves[leave._id] = leave;
      this.getAllLeaves();
    }
    this.initAllLeaveEvents();
    this.sortMyLeavesByDate();
    this.closeLeaveDialog();
  }

  deleteLeave(leave: ILeave, employee: IEmployee): void {
    this.subscription.add(
      this.leaveService.deleteLeave(leave).subscribe({
        next: () => {
          this.toastr.success('Leave was successfully deleted');
          this.updateLeavesAfterDelete(leave);
          // employee.vacationDays += this.calculateWorkDays(leave);
          // this.authService.setCurrentEmployee(employee);
          // this.updateEmployee(employee);
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  /**
   * Removes deleted leave from all arrays to instantly display it.
   * @param {Leave} leave - leave that was deleted
   */
  private updateLeavesAfterDelete(leave: ILeave): void {
    delete this.allLeaves[leave._id];
    delete this.leaves[leave._id];
    delete this.myLeaves[leave._id];
    if (leave.employeeId === this.authService.getCurrentEmployee()._id) {
      delete this.myLeaves[leave._id];
    }
    this.initAllLeaveEvents();
    this.closeLeaveDialog();
  }

  private getAllLeaves(): void {
    this.subscription.add(
      this.leaveService.getAllLeaves().subscribe({
        next: (res: LeavesResponse) => {
          this.allLeaves = res.DATA;
          this.myLeaves = res.DATA.filter((leave) => leave.employeeId === this.authService.getCurrentEmployee()._id);
          this.createDatesForLeaves();
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  // Createas a new Leave Class for each Leave to format the dates
  createDatesForLeaves(): void {
    this.leaves = this.leaves.map((leave) => new Leave(leave));
    this.allLeaves = this.allLeaves.map((leave) => new Leave(leave));
    this.myLeaves = this.myLeaves.map((leave) => new Leave(leave));

    this.initAllLeaveEvents();
    this.sortMyLeavesByDate();
  }

  // Gets all leaves that concern previous, current or next month
  private getCurrentLeaves(): void {
    this.subscription.add(
      this.leaveService.getCurrentLeaves(this.month, this.year).subscribe({
        next: (res: LeavesResponse) => {
          this.leaves = res.DATA;
          this.getAllLeaves();
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  /**
   * Gets all dates of specific month and pushes them to array
   * @param {Date} date - function gets all dates of same month of date.
   */
  private getDaysInMonth(date: Date): void {
    this.datesOfCurrentMonth = [];
    while (date.getMonth() == this.month) {
      const formattedDate =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        date.getDate().toString().padStart(2, '0');
      const dayOfWeek = date.getDay();
      this.datesOfCurrentMonth.push({
        weekday: date.toLocaleString('en-US', { weekday: 'long' }),
        date: formattedDate,
        dayOfWeek: dayOfWeek,
      });
      date.setDate(date.getDate() + 1);
    }
  }

  switchToPreviousMonth(): void {
    if (this.month === 0) {
      this.month = 11;
      this.year--;
    } else {
      this.month--;
    }
    this.createDate();
  }

  switchToNextMonth(): void {
    if (this.month === 11) {
      this.month = 0;
      this.year++;
    } else {
      this.month++;
    }
    this.createDate();
  }

  // function gets called after month of calender is changed
  private createDate(): void {
    this.getDaysInMonth(new Date(this.year, this.month, 1));
    this.getCurrentLeaves();
  }

  isToday(date: DateOfMonth): boolean {
    const today = new Date();
    const todayString = today.toISOString().split('T')[0];

    return date.date === todayString;
  }

  /**
   * Checks for each day if there is an absence booked
   * @param {number} row - Each employee in calender is one row
   * @param {number} column - Each day of current month is one column
   */
  isLeave(row: number, column: number, leaveTypeNumber: number): boolean {
    return this.allLeaveEvents[row][column + 1]?.key === leaveTypeNumber;
  }

  //Creates an Array and initializes events for calendar
  private initAllLeaveEvents(): void {
    for (let i = 0; i < this.employees.length; i++) {
      this.allLeaveEvents[i] = [];
      for (let j = 0; j < this.datesOfCurrentMonth.length + 1; j++) {
        this.allLeaveEvents[i][j] = { id: this.employees[i]?._id, key: 0 };
      }
    }
    this.isLoading = false;
    this.formatLeavesForCalendar();
  }

  /**
   * Adds calendar startdate and enddate to all leaves.
   * Changes actual start and enddate to first or last day of calendar if dates are in another month
   */
  private formatLeavesForCalendar(): void {
    this.leaves.map((leave) => {
      if (
        this.getMonth(leave.startDate as Date) === this.month &&
        this.getMonth(leave.endDate as Date) === this.month
      ) {
        leave.calendarStartDate = leave.startDate as Date;
        leave.calendarEndDate = leave.endDate as Date;
      }
      if (this.getMonth(leave.endDate as Date) != this.month) {
        leave.calendarEndDate = this.getLastDayOfMonth(leave.startDate as Date);
        leave.calendarStartDate = leave.startDate as Date;
      }
      if (this.getMonth(leave.startDate as Date) != this.month) {
        leave.calendarStartDate = this.getFirstDayOfMonth(leave.endDate as Date);
        leave.calendarEndDate = leave.endDate as Date;
      }
    });

    this.setLeavesInCalendar();
  }

  // Checks all leaves and changes key of each day a leave is booked to 1 in calendar
  private setLeavesInCalendar(): void {
    this.leaves.forEach((currentLeave) => {
      const startingDay = new Date(currentLeave.calendarStartDate as string).getDate();
      const endingDay = new Date(currentLeave.calendarEndDate as string).getDate();
      const employeeIndex = this.employees.findIndex((employee) => employee._id === currentLeave.employeeId);
      for (let day = startingDay; day < endingDay + 1; day++) {
        if (this.allLeaveEvents[employeeIndex]) {
          this.allLeaveEvents[employeeIndex][day].key = currentLeave.type === LeaveTimeType.OVERTIME ? 2 : 1;
        }
      }
    });
  }

  public onCalendarMouseEnter(): void {
    const calendar = document.getElementById('calendar');
    calendar.addEventListener('wheel', this.scollHorizontally);
  }

  public onCalendarMouseLeave(): void {
    const calendar = document.getElementById('calendar');
    calendar.removeEventListener('wheel', this.scollHorizontally);
  }

  private scollHorizontally(event: WheelEvent): void {
    event.preventDefault();
    event.stopPropagation();
    const calendar = document.getElementById('calendar');
    if (event.deltaY > 0) {
      calendar.scrollLeft += 100;
    } else {
      calendar.scrollLeft -= 100;
    }
  }

  /**
   * Function is called each time employee clicks calendar and opens clicked event
   * or 'add new event' dialog if there is no event in clicked cell
   * @param {number} row - Row that is clicked.
   * @param {Date} date - Date that is clicked.
   */
  public detectLeaveEvent(row: number, date: DateOfMonth): void {
    const initiator = this.authService.getCurrentEmployee();
    const clickedDate = new Date(date.date);
    const IDofEmployee = this.employees[row]._id;

    const event = this.leaves.find(
      (p) => p.calendarStartDate <= clickedDate && p.calendarEndDate >= clickedDate && p.employeeId === IDofEmployee,
    );

    if (event && (event.employeeId == initiator._id || initiator.isAdmin)) {
      this.openEditLeaveDialog(event);
    }
  }

  public openEditLeaveDialog(leave: ILeave): void {
    if (leave.type === LeaveTimeType.OVERTIME) {
      this.leaveDialog.headline = 'Edit Overtime Leave';
    } else if (leave.type === LeaveTimeType.SICK) {
      this.leaveDialog.headline = 'Edit Sick Leave';
    }
    this.leaveDialog.type = 'edit';
    this.leaveData = leave;
    this.isLeaveDialogOpen = true;
  }

  public openAddLeaveDialog(): void {
    this.leaveData.employeeId = this.authService.getCurrentEmployee()._id;
    this.leaveData.employeeName = this.authService.getCurrentEmployee().firstname;
    this.leaveDialog.headline = 'Add New Overtime Leave';
    this.leaveDialog.type = 'add';
    this.isLeaveDialogOpen = true;
  }

  public openConfirmDeleteDialog(leave: ILeave): void {
    this.dataToConfirm.headline = 'Are you sure?';
    this.dataToConfirm.text = `Do you really want to delte this leave?`;
    this.dataToConfirm.image = 'delete';
    this.dataToConfirm.leave = leave;
    this.isConfirmDialogOpen = true;
  }

  public closeConfirmDialog(): void {
    this.isConfirmDialogOpen = false;
  }

  public closeLeaveDialog(): void {
    this.isLeaveDialogOpen = false;
  }
  private sortMyLeavesByDate(): void {
    this.myLeaves.sort((b, a) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime());
  }

  private getFirstDayOfMonth(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  private getLastDayOfMonth(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }

  private getMonth(date: Date): number {
    return new Date(date).getMonth();
  }

  openClockifyVacationPlanning(): void {
    window.open('https://app.clockify.me/vacation-planning', '_blank');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
