<div class="flex flex-col bg-lightGrayish px-6 max-lg:mb-5">
  <div class="flex items-center text-veryFaintBlack max-lg:justify-between">
    <p class="w-28 text-xs font-semibold lg:w-[85%] lg:text-base">
      {{ firstText }} <br />
      {{ secondText }}
    </p>
    @if (type === UserOberviewType.BIRTHDAY) {
      <p
        class="flex w-64 justify-end whitespace-nowrap border-l-2 text-xs font-semibold text-black max-sm:w-28 lg:text-base xl:min-w-[5rem]"
      >
        {{ getShortEmployeeName(nextBirthdayData.firstname + ' ' + nextBirthdayData.lastname) }}
        {{ nextBirthdayData.birthdate }}
      </p>
    } @else if (type === UserOberviewType.OVERTIME) {
      <p
        class="flex w-64 justify-end whitespace-nowrap border-l-2 text-xs font-semibold text-black max-sm:w-28 lg:text-base xl:min-w-[5rem]"
      >
        {{ helperService.formatMinutes(currentEmployee.overtimeBalance) }}
        Hours
      </p>
    } @else if (type === UserOberviewType.VACATION) {
      <p
        class="flex w-64 justify-end whitespace-nowrap border-l-2 text-xs font-semibold text-black max-sm:w-28 lg:text-base xl:min-w-[5rem]"
      >
        {{ currentEmployee.vacationDays }}
        Days
      </p>
    } @else if (type === UserOberviewType.LEAVES_TODAY) {
      <div class="flex w-64 min-w-max flex-col">
        <!-- $any is used cause ng2-tooltip is not working properly with typescript "strictTemplate" option -->
        <div
          [tooltip]="$any(tooltipTemplate)"
          contentType="template"
          class="flex max-h-[70px] flex-col items-end overflow-hidden text-ellipsis border-l-2 text-xs font-semibold text-black max-sm:w-28 lg:text-base xl:min-w-[5rem]"
        >
          @if (leavesOfToday.length > 0) {
            @for (leave of leavesOfToday; track leave) {
              <p>
                {{ getShortEmployeeName(leave.employeeName) }}
              </p>
            }
          } @else {
            <p>-</p>
          }
        </div>
        @if (leavesOfToday.length > 3) {
          <span class="text-end leading-none text-black">...</span>
        }
      </div>
    }
  </div>
</div>
<ng-template #tooltipTemplate>
  <div class="p-4">
    @for (leave of leavesOfToday; track leave) {
      <div class="flex gap-4">
        <p>
          {{ getShortEmployeeName(leave.employeeName) }}
        </p>
      </div>
    }
  </div>
</ng-template>
