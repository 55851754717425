import { Component, Input } from '@angular/core';
import { UserOverviewType } from 'src/app/enum/user-overview-type.enum';
import { IEmployee } from 'src/app/models/employee.model';
import { ILeave } from 'src/app/models/leave.model';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-user-overview-box',
  templateUrl: './user-overview-box.component.html',
  styleUrl: './user-overview-box.component.scss',
})
export class UserOverviewBoxComponent {
  @Input() public firstText = '';
  @Input() public secondText = '';

  @Input() public type: UserOverviewType;

  @Input() public nextBirthdayData? = {} as IEmployee;
  @Input() public leavesOfToday?: ILeave[] = [];

  public currentEmployee = {} as IEmployee;

  public UserOberviewType = UserOverviewType;
  constructor(
    private authService: AuthService,
    public helperService: HelperService,
  ) {
    this.currentEmployee = this.authService.getCurrentEmployee();
  }

  public getShortEmployeeName(employeeName: string): string {
    const [firstName, lastName] = employeeName.split(' ');
    return `${firstName} ${lastName?.charAt(0)}.`;
  }
}
