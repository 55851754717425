import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ILeave } from '../models/leave.model';
import { LeaveTimeType } from '../enum/leave.enum';

@Injectable({
  providedIn: 'root',
})
export class LeaveService {
  constructor(private http: HttpClient) {}

  public addLeave(leave: ILeave): Observable<any> {
    const formData = this.createFormData(leave);

    return this.http.post(environment.baseUrl + `/leave`, formData);
  }

  public deleteLeave(leave: ILeave): Observable<any> {
    return this.http.delete(environment.baseUrl + `/leave/${leave._id}`);
  }

  public getAllLeaves(type: LeaveTimeType = LeaveTimeType.ALL): Observable<any> {
    const url = environment.baseUrl + `/leave/all${type === LeaveTimeType.SICK ? '?sickLeave=true' : ''}`;
    return this.http.get(url);
  }
  public getLeavsOfToday(): Observable<any> {
    return this.http.get(environment.baseUrl + `/leave/today`);
  }

  public getCurrentLeaves(month: number, year: number): Observable<any> {
    const currentMonth = month + 1;
    return this.http.get(environment.baseUrl + `/leave?month=${currentMonth}&year=${year}`);
  }

  public updateLeave(leave: ILeave): Observable<any> {
    const formData = this.createFormData(leave);

    return this.http.put(environment.baseUrl + `/leave/${leave._id}`, formData);
  }

  public getLeavesPdf(fileId: string): Observable<any> {
    return this.http.get(environment.baseUrl + `/products/image/${fileId}`, { responseType: 'blob' });
  }

  private createFormData(leave: ILeave): FormData {
    const formData: FormData = new FormData();

    formData.append('employeeId', leave.employeeId);
    formData.append('employeeName', leave.employeeName);
    formData.append('startDate', leave.startDate as string);
    formData.append('endDate', leave.endDate as string);
    formData.append('calendarStartDate', leave.calendarStartDate as string);
    formData.append('calendarEndDate', leave.calendarEndDate as string);
    formData.append('type', leave.type);

    if (leave.sickNote) {
      if (typeof leave.sickNote === 'string') {
        formData.append('sickNote', leave.sickNote);
      } else {
        formData.append('sickNote', leave.sickNote, leave.sickNote.name);
      }
    }

    if (leave.isClosed != null) {
      formData.append('isClosed', leave.isClosed.toString());
    }

    return formData;
  }
}
