import { ETrainingMethod } from '../enum/training-type.enum';
import { IEmployee } from './employee.model';

export interface ITraining {
  _id?: string;
  createdAt: Date;
  description: string;
  trainingDate: string;
  duration: number; //in Minutes
  employeeId: string | IEmployee;
  auditorId?: string;
  state: ETrainingState;
  type: ETrainingType;
  method: ETrainingMethod;
  comment?: string;
  reason: string;
}

export enum ETrainingState {
  REQUESTED,
  APPROVED,
  REJECTED,
}

export enum ETrainingType {
  UNSET,
  EXTERNAL,
  INTERNAL,
}
