import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ITraining } from '../models/training.model';

@Injectable({
  providedIn: 'root',
})
export class TrainingService {
  constructor(private http: HttpClient) {}

  public getAllTrainings(showPastRequests: boolean): Observable<any> {
    return this.http.get(environment.baseUrl + `/training/requests?isRequested=${showPastRequests}`);
  }
  public getTrainingsOfEmployee(employeeId: string, showPastRequests: boolean): Observable<any> {
    return this.http.get(environment.baseUrl + `/training/${employeeId}?isRequested=${showPastRequests}`);
  }

  public getAmountOpenTrainingsRequests(): Observable<any> {
    return this.http.get(environment.baseUrl + '/training/amount-requested');
  }
  public createTraining(training: ITraining): Observable<any> {
    return this.http.post(environment.baseUrl + '/training', training);
  }
  public handleTrainingRequest(training: ITraining, rejectionReason: string): Observable<any> {
    return this.http.put(environment.baseUrl + `/training/request/${training._id}`, { training, rejectionReason });
  }
}
