import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EProductType, IProduct } from 'src/app/models/product.model';
import { IEmployee } from 'src/app/models/employee.model';
import { EClothingSize } from 'src/app/enum/size.enum';
import { IConfirmData } from 'src/app/models/confirm-data';
import { ProductService } from 'src/app/services/product.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrl: './product.component.scss',
})
export class ProductComponent {
  @Input() product = {} as IProduct;
  @Input() currentUser = {} as IEmployee;

  @Output() onBuyProduct = new EventEmitter<IProduct>();
  @Output() onEditProduct = new EventEmitter<IProduct>();
  @Output() onDeleteProduct = new EventEmitter<IProduct>();

  public EProductType = EProductType;
  public EClothingSize = EClothingSize;

  public showShopDialog = false;
  public showAddProductDialog = false;
  public showDeleteProductDialog = false;
  public deletionInfo = {} as IConfirmData;
  public productsImage: string = null;
  private subscription = new Subscription();

  public imageLoading = true;
  constructor(
    private productService: ProductService,
    private toastService: ToastrService,
  ) {}

  ngOnInit() {
    this.getProductsImgae();
  }

  public getProductsImgae(): void {
    this.subscription.add(
      this.productService.getProductsImage(this.product.image as string).subscribe({
        next: (res) => {
          this.productsImage = URL.createObjectURL(res);
          this.imageLoading = false;
        },
        error: (err) => {
          this.toastService.error(err.error.MESSAGE);
        },
      }),
    );
  }
  public onOpenShopDialog(size?: EClothingSize): void {
    this.product.size = size;
    this.showShopDialog = true;
  }

  public closeShopDialog(): void {
    this.showShopDialog = false;
  }

  public onBuyProductClick(): void {
    this.onBuyProduct.emit(this.product);
  }
  public onDeleteProductClick(): void {
    this.onDeleteProduct.emit(this.product);
  }
  public onEditProductClick(product: IProduct): void {
    this.onEditProduct.emit(product);
  }

  public onShowEditDialog(): void {
    this.showAddProductDialog = true;
  }

  public onShowDeleteDialog(): void {
    this.deletionInfo.headline = 'Delete this Product?';
    this.deletionInfo.text = `Are you sure to delete the Product ${this.product.title}?`;
    this.deletionInfo.image = 'delete';
    this.showDeleteProductDialog = true;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
