import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class FirstLoginGuard {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  canActivate(): boolean {
    const currentUser = this.authService.getCurrentEmployee();
    if (currentUser?.firstLogin) {
      this.router.navigate(['/personnel-file']);
      return false;
    }
    return true;
  }
}
