import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss',
})
export class DropdownComponent {
  @Input() elements: any[];
  @Input() placeholder: string;
  @Input() isEmployeeDropdown = false;
  @Input() isRevenueDropdown = false;
  @Input() selectedElement: any;

  @Output() selectElement = new EventEmitter<any>();

  public showDropdown = false;
  public dropDownIcon = faAngleDown;

  public onSelectElement(element) {
    this.showDropdown = false;
    this.selectElement.emit(element);
    this.selectedElement = this.isEmployeeDropdown
      ? element.firstname + ' ' + element.lastname
      : this.isRevenueDropdown
        ? element.value
        : element;
  }

  public resetSelectedElement(): void {
    this.selectedElement = null;
  }

  public onToggleDropDown(): void {
    this.showDropdown = !this.showDropdown;
  }
}
