import { Component, ViewChild } from '@angular/core';
import { faAngleDown, faChevronUp, faUserPlus } from '@fortawesome/free-solid-svg-icons';

import { ActiveToast, ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { DropdownComponent } from 'src/app/components/dropdown/dropdown.component';

import { DialogType } from 'src/app/enum/dialog-type.enum';
import { EmployeeRole } from 'src/app/enum/employeeRole.enum';

import { SortAdmin } from 'src/app/models/admin-sort.model';
import { ICoinTransfer } from 'src/app/models/coinTransfer.model';
import { IConfirmData, IConfirmDialogData } from 'src/app/models/confirm-data';
import { IEmployee } from 'src/app/models/employee.model';
import { IGoal } from 'src/app/models/goal.model';
import { HistoryDialogData } from 'src/app/models/history-dialog.model';
import { EmployeesResponse, GoalResponse } from 'src/app/models/response.model';
import { ETrainingState, ITraining } from 'src/app/models/training.model';
import { EState, ITransaction } from 'src/app/models/transaction.model';
import { AuthService } from 'src/app/services/auth.service';
import { EmployeeService } from 'src/app/services/employee.service';
import { GoalsService } from 'src/app/services/goals.service';
import { HelperService } from 'src/app/services/helper.service';
import { TrainingService } from 'src/app/services/training.service';
import { TransactionService } from 'src/app/services/transaction.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent {
  @ViewChild(DropdownComponent) employeeDropdown: DropdownComponent;
  public purchaseEntryConditions: SortAdmin[] = [
    { label: 'Date' },
    { label: 'Employee' },
    { label: 'Product' },
    { label: 'Price' },
    { label: 'Address' },
  ];

  public goalText: string;
  public allCompanyGoals: IGoal[] = [];

  public addUserIcon = faUserPlus;
  public arrowUpIcon = faChevronUp;
  public dropDownIcon = faAngleDown;

  public dataToConfirm = {} as IConfirmData;
  public confirmTrainingData = {} as IConfirmDialogData;
  public employees: IEmployee[];

  public isConfirmDialogOpen = false;
  public showTrainingsConfirmDialog = false;
  public manageEmployeeDialog = false;
  public showAllEmployees = false;

  public searchText: string;
  public filteredEmployees: IEmployee[] = [];
  public tester: IEmployee[] = [];
  private subscriptions = new Subscription();

  public activeSortCondition: string = '';
  public productRequests = [] as ITransaction[];

  public eState = EState;
  public requestArticleToConfirm: ITransaction;

  public environment = environment;

  public sortConditions: SortAdmin[] = [
    { id: 'firstname', label: 'Employee' },
    { id: 'overtimeBalance', label: 'Overtimes' },
    { id: 'vacationDays', label: 'Holidays' },
    { id: 'coinBalance', label: 'Coin Balance' },
  ];
  public sortConditionsTester: SortAdmin[] = [
    { id: 'firstname', label: 'Tester' },
    { id: 'overtimeBalance', label: 'Overtimes' },
    { id: 'vacationDays', label: 'Holidays' },
    { id: 'coinBalance', label: 'Coin Balance' },
  ];

  public coinTransfer = {} as ICoinTransfer;
  public selectedEmployee: IEmployee;
  public showEmployeesDropdown = false;
  public trainings: ITraining[];
  public showIsRequested = true;
  public ETrainingState = ETrainingState;

  public showRequestedTransactions = true;

  public showHistoryDialog: boolean;
  public DialogType = DialogType;
  public historyDialogData = {
    employee: {} as IEmployee,
  } as HistoryDialogData;

  constructor(
    public helperService: HelperService,
    private transactionService: TransactionService,
    private trainingService: TrainingService,
    private goalsService: GoalsService,
    private employeeService: EmployeeService,
    private toastr: ToastrService,
    private authService: AuthService,
  ) {
    this.getEmployees();
    if (environment.showTester) this.getAllTesterAccounts();
    this.getAllTransactions(true);
    this.getAllTrainings();
  }

  public getAllTrainings(showIsRequested = true): void {
    this.subscriptions.add(
      this.trainingService.getAllTrainings(showIsRequested).subscribe({
        next: (res) => {
          this.trainings = res.DATA;
          this.helperService.setAmountRequestForAdminBadgeChanged(true);
        },
        error: (err) => {
          console.error(err);

          this.toastr.error('An error occurred fetching the trainings');
        },
      }),
    );
  }

  public getTrainingEmployee(training: ITraining): string {
    const employee = training.employeeId as IEmployee;
    return employee.firstname + ' ' + employee.lastname;
  }
  public getTrainingDuration(trainingDurationInMinutes: number): string {
    return this.helperService.formatMinutes(trainingDurationInMinutes);
  }

  public toggleOpenTrainings(): void {
    this.showIsRequested = !this.showIsRequested;
    this.getAllTrainings(this.showIsRequested);
  }
  public approveTraining(training: ITraining): void {
    this.confirmTrainingData = {
      headline: 'Approve training',
      text: 'Are you sure you want to approve this training?',
      training: training,
      newState: ETrainingState.APPROVED,
    };
    this.showTrainingsConfirmDialog = true;
  }

  public rejectTraining(training: ITraining): void {
    this.confirmTrainingData = {
      headline: 'Reject training',
      text: 'Are you sure you want to reject this training? Please enter the reason for rejection!',
      training: training,
      isRejectTraining: true,
      newState: ETrainingState.REJECTED,
    };
    this.showTrainingsConfirmDialog = true;
  }

  public onConfirmTraining(data: IConfirmDialogData): void {
    const training = data.training;
    if (data.newState === ETrainingState.APPROVED) training.state = ETrainingState.APPROVED;
    else if (data.newState === ETrainingState.REJECTED) training.state = ETrainingState.REJECTED;
    this.updateTraining(training, data.rejectionReason);
  }

  public updateTraining(training: ITraining, rejectionReason?: string): void {
    this.subscriptions.add(
      this.trainingService.handleTrainingRequest(training, rejectionReason).subscribe({
        next: (res) => {
          const trainingRes = res.DATA;
          this.toastr.success(
            trainingRes.state === ETrainingState.APPROVED ? 'Training has been approved' : 'Training has been rejected',
          );
          this.getAllTrainings();
        },
        error: (res) => {
          console.error(res);
          this.toastr.error(res.error.DATA);
        },
      }),
    );
  }

  public getTrainingDate(training: ITraining): Date {
    return new Date(training.trainingDate);
  }

  public onCreateCompanyGoal(goalText: string): ActiveToast<any> | void {
    if (!goalText) {
      return this.toastr.error('Goal text is empty');
    }
    this.createCompanyGoal(goalText);
  }

  private createCompanyGoal(goalText: string): void {
    this.subscriptions.add(
      this.goalsService.createNewCompanyGoal(goalText).subscribe({
        next: (res: GoalResponse) => {
          this.helperService.setCompanyGoals([...this.allCompanyGoals, res.DATA]);
          this.toastr.success('Company goal has been created');
          this.goalText = '';
        },
        error: (err) => {
          console.error(err);
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  public searchEmployees(): void {
    if (!this.searchText) {
      this.filteredEmployees = this.employees;
      return;
    }
    this.filteredEmployee();
  }

  private filteredEmployee(): void {
    this.filteredEmployees = this.employees?.filter(
      (employee) =>
        employee.firstname.toLowerCase().includes(this.searchText.toLowerCase()) ||
        employee.lastname.toLowerCase().includes(this.searchText.toLowerCase()),
    );
  }

  public openEmployeeDialog(dialogType: 'add' | 'edit', employee?: IEmployee): void {
    this.dataToConfirm.employee = employee;
    this.dataToConfirm.type = dialogType;
    this.manageEmployeeDialog = true;
  }
  public openHistoryDialog(dialogType: DialogType, employee: IEmployee): void {
    this.historyDialogData.employee = employee;
    this.historyDialogData.type = dialogType;

    this.showHistoryDialog = true;
  }

  public copyTesterPassword(): void {
    navigator.clipboard.writeText('EastWestSouth2019!').then(() => {
      this.toastr.success('Copied password to clipboard');
    });
  }

  public getEmployees(): void {
    this.subscriptions.add(
      this.employeeService.getAllEmployees().subscribe({
        next: (res: EmployeesResponse) => {
          this.employees = res.DATA;

          this.searchEmployees();
        },
        error: () => {
          this.toastr.error('Employees could not be loaded');
        },
      }),
    );
  }
  public getAllTesterAccounts(): void {
    this.subscriptions.add(
      this.employeeService.getAllEmployees(true).subscribe({
        next: (res: EmployeesResponse) => {
          this.tester = res.DATA;
        },
        error: () => {
          this.toastr.error('Employees could not be loaded');
        },
      }),
    );
  }
  public getNotArchivedEmployees(): IEmployee[] {
    return this.employees?.filter((employee) => !employee.isArchived);
  }

  /**
   * @param {IConfirmData} data - Contains data of employee that should either
   * be created or updated.
   */
  public manageEmployeeDialogHandler(data: IConfirmData): void {
    if (data.type === 'add') {
      this.addNewEmployee(data.employee);
    } else if (data.type === 'edit') {
      this.editEmployee(data.employee);
    }
  }

  private addNewEmployee(employee: IEmployee): void {
    this.subscriptions.add(
      this.employeeService.addNewEmployee(employee).subscribe({
        next: () => {
          this.toastr.success('Employee has been created');
          this.employees.push(employee);
          this.getEmployees();
          this.getAllTesterAccounts();
        },
        error: () => {
          this.toastr.error('Employee could not be created');
        },
      }),
    );
  }

  private editEmployee(employee: IEmployee): void {
    this.subscriptions.add(
      this.employeeService.editEmployee(employee).subscribe({
        next: () => {
          this.toastr.success('Employee has been edited');
          this.updateEmployee(employee);
        },
        error: () => {
          this.toastr.error('Employee could not be edit');
        },
      }),
    );
  }

  public updateEmployee(employee: IEmployee): void {
    if (employee._id === this.authService.getCurrentEmployee()._id) {
      this.authService.setCurrentEmployee(employee);
    }
    this.getEmployees();
  }

  /**
   * Archives or reactivates employee depending on type
   * @param {IConfirmData} data - Confirm information.
   */
  public confirmedChangesHandler(data: IConfirmData): void {
    if (data.type === 'delete') {
      this.archiveEmployee(data);
    } else if (data.type === 'reactivate') {
      this.reactivateEmployee(data);
    } else if (data.type === 'removeArticle') {
      this.cancelArticleRequest(data);
    } else if (data.type === 'acceptArticle') {
      this.acceptArticle(data);
    }
  }

  private updateEmployeeArchiveStatus(employee: IEmployee, isArchived: boolean): void {
    employee.isArchived = isArchived;
  }

  private archiveEmployee(data: IConfirmData): void {
    this.subscriptions.add(
      this.employeeService.deleteEmployee(data.employee._id).subscribe({
        next: (res) => {
          this.toastr.success(res.MESSAGE);
          if (data.employee.role !== EmployeeRole.TESTER) this.updateEmployeeArchiveStatus(data.employee, true);
          else {
            this.tester = this.tester.filter((tester) => tester._id !== data.employee._id);
          }
        },
        error: (err: any) => {
          this.toastr.error(err);
        },
      }),
    );
  }

  private reactivateEmployee(data: IConfirmData): void {
    data.employee.isArchived = false;
    this.subscriptions.add(
      this.employeeService.editEmployee(data.employee).subscribe({
        next: () => {
          this.toastr.success('Employee has been reactivated');
          this.updateEmployeeArchiveStatus(data.employee, false);
        },
        error: (err: any) => {
          this.toastr.error(err);
        },
      }),
    );
  }

  private getAllTransactions(getRequested: boolean): void {
    this.subscriptions.add(
      this.transactionService.getAllTransactions(getRequested).subscribe({
        next: (res) => {
          this.productRequests = res.DATA;
          this.helperService.setAmountRequestForAdminBadgeChanged(true);
        },
        error: () => {
          this.toastr.error('Transactions could not be loaded');
        },
      }),
    );
  }

  public sortEmployeesByCondition(sortCondition: string): void {
    if (sortCondition == 'firstname') {
      this.employees.sort((a, b) => a.firstname.localeCompare(b.firstname));
    } else {
      this.employees.sort((a, b) => b[sortCondition] - a[sortCondition]);
    }
    this.activeSortCondition = sortCondition;
  }

  public openConfirmDialog(type: 'delete' | 'reactivate', employee: IEmployee): void {
    this.dataToConfirm.headline = 'Are you sure?';
    this.dataToConfirm.type = type;
    this.dataToConfirm.employee = employee;
    if (type == 'delete') {
      this.dataToConfirm.text =
        employee.role === EmployeeRole.TESTER
          ? 'Do you really want to delete this Tester-Account?'
          : `Do you really want to move ${employee.firstname} to archive?`;
      this.dataToConfirm.image = 'delete';
    } else {
      this.dataToConfirm.text = `Do you really want to reactivate ${employee.firstname}?`;
      this.dataToConfirm.image = 'users';
    }
    this.isConfirmDialogOpen = true;
  }

  public closeEmployeeDialog(): void {
    this.manageEmployeeDialog = false;
  }

  public closeConfirmDialog(): void {
    this.isConfirmDialogOpen = false;
    this.showTrainingsConfirmDialog = false;
  }

  public onTransferCoin(): void {
    if (!this.coinTransfer.amount || !this.coinTransfer.employee || !this.coinTransfer.reason)
      this.toastr.error(`Please fill all required fields first`);
    else
      this.subscriptions.add(
        this.employeeService.updateCoins(this.coinTransfer).subscribe({
          next: () => {
            if (this.authService.getCurrentEmployee()._id === this.coinTransfer.employee._id) {
              this.helperService.setAmountRequestForAdminBadgeChanged(true);
            }
            this.toastr.success(`The coins of ${this.getEmployeeName(this.coinTransfer)} has been updated`);
            this.coinTransfer = {} as ICoinTransfer;
            this.employeeDropdown.resetSelectedElement();
            this.getEmployees();
            this.helperService.userCoinsChanged$.next(true);
          },
          error: (err: any) => {
            this.toastr.error(err);
          },
        }),
      );
  }

  public onSelectEmployee(employee): void {
    this.coinTransfer.employee = employee;
    this.onToggleDropDown();
  }

  public onToggleDropDown(): void {
    this.showEmployeesDropdown = !this.showEmployeesDropdown;
  }

  public managePurchasing(type: 'acceptArticle' | 'removeArticle', article: ITransaction): void {
    this.dataToConfirm.headline = 'Are you sure?';
    this.dataToConfirm.type = type;

    if (type == 'acceptArticle') {
      this.dataToConfirm.text = `Do you really want to accept this purchasing?`;
      this.dataToConfirm.image = 'feedback'; //temporary
    } else {
      this.dataToConfirm.text = `Do you really want to reject this purchasing?`;
      this.dataToConfirm.image = 'delete';
    }
    this.requestArticleToConfirm = article;
    this.isConfirmDialogOpen = true;
  }

  private cancelArticleRequest(data) {
    this.transactionService.setTransactionStatus(this.requestArticleToConfirm, EState.CANCELED).subscribe({
      next: () => {
        const employeeOfRequest = this.requestArticleToConfirm.employee;
        if (this.authService.getCurrentEmployee()._id === employeeOfRequest._id) {
          this.helperService.userCoinsChanged$.next(true);
        }
        this.toastr.success('Article request has been cancelled');
        this.requestArticleToConfirm = null;
        this.getAllTransactions(this.showRequestedTransactions);
      },
      error: (err: any) => {
        this.toastr.error(err);
        this.requestArticleToConfirm = null;
      },
    });
  }

  private acceptArticle(data) {
    this.transactionService.setTransactionStatus(this.requestArticleToConfirm, EState.APPROVED).subscribe({
      next: () => {
        const employeeOfRequest = this.requestArticleToConfirm.employee;

        this.toastr.success('Article request has been accepted');
        this.requestArticleToConfirm = null;
        this.getAllTransactions(this.showRequestedTransactions);
      },
      error: (err: any) => {
        console.error(err);
        this.toastr.error(err);
        this.requestArticleToConfirm = null;
      },
    });
  }

  public toggleTransactions(showDoneTransactions: boolean) {
    this.getAllTransactions(showDoneTransactions);
  }

  public getEmployeeName(transfer: ICoinTransfer) {
    const employee = transfer.employee;
    return employee.firstname + ' ' + employee.lastname;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
