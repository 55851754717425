<div class="flex w-full flex-col items-center justify-center gap-5">
  <div class="grid w-full max-w-7xl gap-4 md:grid-cols-2">
    <div class="mb-5 flex min-h-[200px] w-full flex-col gap-4 bg-lightGrayish p-5 lg:gap-7">
      <app-text-content-box
        id="provision"
        [headline]="'Provision'"
        [headerIcon]="'🤑'"
        class="-mt-20"
      ></app-text-content-box>
      <a href="https://app.clickup.com/9004057286/v/dc/8cay0p6-281/8cay0p6-6095" target="_blank"
        >➡️ Hier mehr erfahren</a
      >
    </div>
    <div class="mb-5 flex min-h-[200px] w-full flex-col gap-4 bg-lightGrayish p-5 lg:gap-7">
      <app-text-content-box
        id="bonussystem"
        [headline]="'Bonussystem'"
        [headerIcon]="'💰'"
        class="-mt-20"
      ></app-text-content-box>
      <a href="https://app.clickup.com/9004057286/v/dc/8cay0p6-281/8cay0p6-7935" target="_blank"
        >➡️ Hier mehr erfahren</a
      >
    </div>
    <div class="mb-5 flex min-h-[200px] w-full flex-col gap-4 bg-lightGrayish p-5 lg:gap-7">
      <app-text-content-box
        id="education"
        [headline]="'Education'"
        [headerIcon]="'📚'"
        class="-mt-20"
      ></app-text-content-box>
      <a href="https://app.clickup.com/9004057286/v/dc/8cay0p6-281/8cay0p6-6255" target="_blank"
        >➡️ Hier mehr erfahren</a
      >
    </div>
    <!--     <div class="mb-5 flex min-h-[200px] w-full flex-col gap-4 bg-lightGrayish p-5 lg:gap-7">
      <app-text-content-box id="raises" [headline]="'Raises'" [headerIcon]="'💵'" class="-mt-20"></app-text-content-box>
      <a href="https://app.clickup.com/9004057286/v/dc/8cay0p6-281/8cay0p6-7935" target="_blank"
        >➡️ Hier mehr erfahren</a
      >
    </div> -->
  </div>
</div>
