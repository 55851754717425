<div
  class="fixed left-0 top-[4em] z-40 flex h-screen min-h-[calc(100vh-4em)] w-60 flex-col bg-lightGrayish text-mediumGray"
>
  <div class="sticky top-0 font-poppins">
    <div class="flex h-[calc(100vh-8em)] flex-col gap-8 overflow-auto p-4">
      <div (click)="hideSidebar()" class="right-4 top-4 flex cursor-pointer items-center">
        collapse
        <img class="h-5" src="assets/img/icons/arrow-left2.png" alt="arrow-left2" />
      </div>

      <h1 class="text-2xl font-bold text-black">Navigation</h1>

      <div
        routerLink="dashboard"
        (click)="
          isPhoneSize ? this.helperService.setSidebarVisibility(false) : this.helperService.setSidebarVisibility(true)
        "
        class="group relative flex h-6 w-full cursor-pointer items-center justify-center"
      >
        <div routerLinkActive="clicked" class="flex w-full items-center gap-2 font-semibold text-semiTransparentBlack">
          <img class="h-7 w-7" src="assets/img/dashboard.png" alt="dashboard" />
          <p>Home</p>
        </div>
      </div>

      <div
        routerLink="mission-values"
        (click)="
          isPhoneSize ? this.helperService.setSidebarVisibility(false) : this.helperService.setSidebarVisibility(true)
        "
        class="group relative flex h-6 w-full cursor-pointer items-center justify-center"
      >
        <div routerLinkActive="clicked" class="flex w-full items-center gap-2 font-semibold text-semiTransparentBlack">
          <span class="flex w-7 scale-125 justify-center text-black">💎</span>
          <p>Mission & Values</p>
        </div>
      </div>

      @if (isCurrentUserAdmin()) {
        <div
          (click)="
            isPhoneSize ? this.helperService.setSidebarVisibility(false) : this.helperService.setSidebarVisibility(true)
          "
          routerLink="admin"
          class="group relative flex h-6 w-full cursor-pointer items-center justify-center"
        >
          <div
            routerLinkActive="clicked"
            class="flex w-full items-center gap-2 font-semibold text-semiTransparentBlack"
          >
            <img class="h-7 w-7" src="assets/img/admin-tools.png" alt="admin-tools" />
            <p>Administration</p>
            @if (amountOpenRequests) {
              <div
                class="absolute right-7 top-0 flex h-4 w-4 items-center justify-center rounded-full bg-sunflowerGold text-[10px] text-white"
              >
                {{ amountOpenRequests }}
              </div>
            }
          </div>
        </div>
      }

      <div
        routerLink="revenues"
        (click)="
          isPhoneSize ? this.helperService.setSidebarVisibility(false) : this.helperService.setSidebarVisibility(true)
        "
        class="group relative flex h-6 w-full cursor-pointer items-center justify-center"
      >
        <div routerLinkActive="clicked" class="flex w-full items-center gap-2 font-semibold text-semiTransparentBlack">
          <img class="h-7 w-7" src="assets/img/turnover.png" alt="turnover" />
          <p>Revenue Targets</p>
        </div>
      </div>

      <div
        routerLink="employee"
        (click)="
          isPhoneSize ? this.helperService.setSidebarVisibility(false) : this.helperService.setSidebarVisibility(true)
        "
        class="group relative flex h-6 w-full cursor-pointer items-center justify-center"
      >
        <div routerLinkActive="clicked" class="flex w-full items-center gap-2 font-semibold text-semiTransparentBlack">
          <img class="h-7 w-7" src="assets/img/vacation.png" alt="vacation" />
          <p>Holiday Planning</p>
        </div>
      </div>
      <div
        routerLink="training"
        (click)="
          isPhoneSize ? this.helperService.setSidebarVisibility(false) : this.helperService.setSidebarVisibility(true)
        "
        class="group relative flex h-6 w-full cursor-pointer items-center justify-center"
      >
        <div routerLinkActive="clicked" class="flex w-full items-center gap-2 font-semibold text-semiTransparentBlack">
          <span class="flex w-7 scale-125 justify-center text-black">🏋🏼</span>
          <p>Training</p>
        </div>
      </div>

      <div
        routerLink="product-ideas"
        (click)="
          isPhoneSize ? this.helperService.setSidebarVisibility(false) : this.helperService.setSidebarVisibility(true)
        "
        class="group relative flex h-6 w-full cursor-pointer items-center justify-center"
      >
        <div routerLinkActive="clicked" class="flex w-full items-center gap-2 font-semibold text-semiTransparentBlack">
          <span class="flex w-7 scale-125 justify-center text-black">💡</span>
          <p>Product Ideas</p>
        </div>
      </div>

      <div
        routerLink="feedback"
        (click)="
          isPhoneSize ? this.helperService.setSidebarVisibility(false) : this.helperService.setSidebarVisibility(true)
        "
        class="group relative flex h-6 w-full cursor-pointer items-center justify-center"
      >
        <div routerLinkActive="clicked" class="flex w-full items-center gap-2 font-semibold text-semiTransparentBlack">
          <img class="h-7 w-7" src="assets/img/feedback.png" alt="feedback" />
          <p>Feedback</p>
        </div>
      </div>

      <div
        routerLink="library"
        (click)="
          isPhoneSize ? this.helperService.setSidebarVisibility(false) : this.helperService.setSidebarVisibility(true)
        "
        class="group relative flex h-6 w-full cursor-pointer items-center justify-center"
      >
        <div routerLinkActive="clicked" class="flex w-full items-center gap-2 font-semibold text-semiTransparentBlack">
          <span class="flex w-7 scale-125 justify-center text-black">📖</span>
          <p>Library</p>
        </div>
      </div>

      <div
        routerLink="42shop"
        (click)="
          isPhoneSize ? this.helperService.setSidebarVisibility(false) : this.helperService.setSidebarVisibility(true)
        "
        class="group relative flex h-6 w-full cursor-pointer items-center justify-center"
      >
        <div routerLinkActive="clicked" class="flex w-full items-center gap-2 font-semibold text-semiTransparentBlack">
          <span class="flex w-7 scale-125 justify-center text-black">🛍️</span>
          <p>42 Shop</p>
        </div>
      </div>

      <div class="group relative flex w-full cursor-pointer items-center justify-center">
        <div routerLink="infos" class="flex w-full flex-col items-center gap-4 font-semibold text-semiTransparentBlack">
          <div
            routerLinkActive="clicked"
            class="flex w-full items-center gap-2 font-semibold text-semiTransparentBlack"
          >
            <span class="flex w-7 scale-125 justify-center !text-black">ℹ️</span>
            <p>Infos</p>
          </div>

          @if (router.url.includes('/infos')) {
            <div
              (click)="
                isPhoneSize
                  ? this.helperService.setSidebarVisibility(false)
                  : this.helperService.setSidebarVisibility(true)
              "
              class="flex flex-col gap-3 text-xs font-semibold text-semiTransparentBlack"
            >
              <a
                href="https://app.clickup.com/9004057286/v/dc/8cay0p6-281/8cay0p6-6095"
                target="_blank"
                fragment="provision"
              >
                <span class="text-black">🤑 </span>
                Provision
              </a>
              <a
                href="https://app.clickup.com/9004057286/v/dc/8cay0p6-281/8cay0p6-7935"
                target="_blank"
                fragment="bonussystem"
              >
                <span class="text-black">💰 </span>
                Bonussystem
              </a>
              <!--               <a [routerLink]="['/infos']" fragment="raises">
                <span class="text-black">💵 </span>
                Raises
              </a> -->
              <a
                href="https://app.clickup.com/9004057286/v/dc/8cay0p6-281/8cay0p6-6255"
                target="_blank"
                fragment="education"
              >
                <span class="text-black">📚 </span>
                Education
              </a>
              <a class="flex" href="https://app.clickup.com/9004057286/v/dc/8cay0p6-281/8cay0p6-5135" target="_blank">
                <span class="text-black">♻️&nbsp;</span>
                <p>Who Does What?</p>
              </a>

              <a href="https://app.clickup.com/9004057286/v/dc/8cay0p6-281" target="_blank">
                <span class="text-black">🧠 </span>
                Wiki
              </a>
            </div>
          }
        </div>
      </div>
    </div>
    <div class="footer sticky bottom-0 flex items-center justify-between bg-[#F8F8F8] px-5 py-4">
      <a href="https://app.clockify.me" target="_blank">
        <img class="w-4" src="../../../assets/img/clockify.png" alt="" />
      </a>
      <a href="https://credentials.42-projects.com/" target="_blank">
        <img class="img-flip-horizontal w-4" src="../../../assets/img/bitwarden.png" alt="" />
      </a>
      <a href="https://app.clickup.com" target="_blank">
        <img class="w-4" src="../../../assets/img/clickup.png" alt="" />
      </a>
      <a href="https://www.office.com/" target="_blank">
        <img class="w-5" src="../../../assets/img/office.png" alt="" />
      </a>
      <a href="https://gitlab.42-projects.com/" class="-ml-2" target="_blank">
        <img class="w-8" src="../../../assets/img/gitlab.png" alt="" />
      </a>
    </div>
  </div>
</div>
