<form class="flex flex-col xl:border-l-2 xl:pl-4">
  <div class="flex w-full flex-col gap-4 xl:flex-row xl:justify-between">
    <div class="mt-4 xl:mt-0" data-cy="overtime-employee-select">
      <app-dropdown
        #dropdownComponent
        [elements]="employees"
        placeholder="Select employee"
        [isEmployeeDropdown]="true"
        (selectElement)="onSelectEmployee($event)"
      ></app-dropdown>
    </div>
    <div class="relative flex items-center">
      <form class="w-full xl:w-[80px]" [formGroup]="timeForm">
        <input
          placeholder="HH:MM"
          class="remove-arrow block h-12 w-full border border-gray-300 p-2.5 placeholder:font-semibold xl:text-center"
          data-cy="overtime-manual-input"
          type="text"
          formControlName="timeInput"
          (input)="helperService.onTimeInput($event, timeForm, true)"
        />
      </form>

      <div
        class="flex h-12 w-16 items-center justify-center border border-l-0 border-gray-300 bg-white p-2 text-sm font-semibold"
      >
        Hours
      </div>
    </div>
    <div class="w-full">
      <input
        type="text"
        class="block h-12 w-full border border-gray-300 p-2.5 placeholder-veryTransparentBlack placeholder:font-semibold"
        data-cy="overtime-manual-reason"
        required
        [(ngModel)]="reason"
        placeholder="Reason"
        name="reason"
      />
    </div>
    <div class="flex w-full justify-end xl:w-fit">
      <button
        (click)="openConfirmDialog('editOvertime')"
        class="flex h-12 w-40 items-center justify-center border-black bg-almostBlack"
        data-cy="overtime-manual-save"
      >
        <img class="h-[18px] cursor-pointer" src="assets/img/icons/save.png" alt="save" />
      </button>
    </div>
  </div>
</form>
@if (confirmDialog) {
  <app-confirm-dialog
    (closeDialog)="closeConfirmDialog()"
    (saveChanges)="updateOvertime()"
    [data]="dataToConfirm"
  ></app-confirm-dialog>
}
