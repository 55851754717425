import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IConfirmDialogData } from 'src/app/models/confirm-data';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrl: './confirmation-dialog.component.scss',
})
export class ConfirmationDialogComponent {
  @Input() data: IConfirmDialogData;
  @Input() onAcceptButtonText: string = 'Accept';

  @Output() closeDialog = new EventEmitter<boolean>();
  @Output() acceptChanges = new EventEmitter<IConfirmDialogData>();

  constructor(private toastrService: ToastrService) {}

  public onAccept(): void {
    if (this.data.isRejectTraining && !this.data.rejectionReason) {
      this.toastrService.error('Please provide a reason for rejection');
      return;
    }
    this.acceptChanges.emit(this.data);
    this.closeDialog.emit(true);
  }

  public onClose(): void {
    this.closeDialog.emit(true);
  }
}
