import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from './guards/admin.guard';
import { AuthGuard } from './guards/auth.guard';
import { AdminComponent } from './pages/admin/admin.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { FeedbackComponent } from './pages/feedback/feedback.component';
import { HolidayPlanning } from './pages/holiday-planning/holiday-planning.component';
import { InfosComponent } from './pages/infos/infos.component';
import { LibraryComponent } from './pages/library/library.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { MissionValuesComponent } from './pages/mission-values/mission-values.component';
import { ProductIdeasComponent } from './pages/product-ideas/product-ideas.component';
import { RevenuesComponent } from './pages/revenues/revenues.component';
import { ShopComponent } from './pages/shop/shop.component';
import { TrainingComponent } from './pages/training/training.component';
import { PersonnelFileComponent } from './pages/personnel-file/personnel-file.component';
import { FirstLoginGuard } from './guards/first-login.guard';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'login', component: LoginPageComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard, FirstLoginGuard] },
  {
    path: 'admin',
    canActivate: [AdminGuard, FirstLoginGuard],
    component: AdminComponent,
  },
  {
    path: 'employee',
    canActivate: [AuthGuard, FirstLoginGuard],
    component: HolidayPlanning,
  },
  {
    path: 'revenues',
    canActivate: [AuthGuard, FirstLoginGuard],
    component: RevenuesComponent,
  },
  {
    path: 'product-ideas',
    canActivate: [AuthGuard, FirstLoginGuard],
    component: ProductIdeasComponent,
  },
  {
    path: 'mission-values',
    canActivate: [AuthGuard, FirstLoginGuard],
    component: MissionValuesComponent,
  },
  {
    path: 'feedback',
    canActivate: [AuthGuard, FirstLoginGuard],
    component: FeedbackComponent,
  },
  {
    path: 'library',
    canActivate: [AuthGuard, FirstLoginGuard],
    component: LibraryComponent,
  },
  {
    path: '42shop',
    canActivate: [AuthGuard, FirstLoginGuard],
    component: ShopComponent,
  },
  {
    path: 'infos',
    canActivate: [AuthGuard, FirstLoginGuard],
    component: InfosComponent,
  },
  {
    path: 'training',
    canActivate: [AuthGuard, FirstLoginGuard],
    component: TrainingComponent,
  },
  {
    path: 'personnel-file',
    canActivate: [AuthGuard],
    component: PersonnelFileComponent,
  },
  { path: '**', redirectTo: 'dashboard' }, // Wildcard-Route when no route is found
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
